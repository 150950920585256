import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { type XYCoord, useDrag, useDrop } from 'react-dnd'
import { Avatar, Stack, Typography, IconButton, Tooltip } from '@mui/material'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import DeleteIcon from '@mui/icons-material/Delete'
import InactivateIcon from '@mui/icons-material/VisibilityOffOutlined'
import ActivateIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import EditIcon from '@mui/icons-material/EditOutlined'
import HideImageIcon from '@mui/icons-material/HideImageOutlined'
import ShowImageIcon from '@mui/icons-material/ImageOutlined'
import ShowLocationIcon from '@mui/icons-material/LocationOnOutlined'
import HideLocationIcon from '@mui/icons-material/LocationOffOutlined'

import { type CategoryFormData } from '../categoryTypes'
import SubCategoryEditTree from './SubCategoryEditTree'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'
import usePortalSetting from 'hooks/usePortalSetting'

type CategoryEditItemProps = {
  index: number
  paths: string[]
  category: CategoryFormData
  onAddCategory: (paths: string[]) => void
  onToggleCategory: (paths: string[], active: boolean) => void
  onToggleResourcesAllowed: (paths: string[], resourcesAllowed: boolean) => void
  onToggleLocationRequired: (paths: string[], locationRequired: boolean) => void
  onDeleteCategory: (paths: string[]) => void
  onEditCategory: (paths: string[], category: CategoryFormData) => void
  currentHoverItem: string | null
  setCurrentHoverItem: (currentHoverItem: string | null) => void
  onMoveCategory: (paths: string[], index: number, toIndex: number) => void
}

type Item = {
  id: string
  index: number
}

const CategoryEditItem: React.FC<CategoryEditItemProps> = ({
  index,
  paths,
  category,
  onAddCategory,
  onToggleCategory,
  onToggleResourcesAllowed,
  onToggleLocationRequired,
  onDeleteCategory,
  onEditCategory,
  currentHoverItem,
  setCurrentHoverItem,
  onMoveCategory,
}) => {
  const { formatMessage } = useIntl()
  const ref = useRef<HTMLLIElement>(null)
  const { getLocalizedContent } = usePortalSetting()
  const pathsString = paths.join('|')
  const clonePaths = [...paths]
  clonePaths.pop()

  const parentPathsString = `root-${clonePaths.join('|')}`

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: parentPathsString,
      item: { id: category.id, index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop()
        console.log(item, didDrop)
      },
    }),
    [category, index],
  )

  const [, drop] = useDrop(
    () => ({
      accept: parentPathsString,
      hover(item: Item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index

        if (dragIndex === hoverIndex) {
          return
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        const clientOffset = monitor.getClientOffset()

        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        const parentPaths = [...paths]
        parentPaths.pop()
        onMoveCategory(parentPaths, dragIndex, hoverIndex)

        item.index = hoverIndex
      },
    }),
    [category, paths, onMoveCategory, index],
  )

  drag(drop(ref))

  return (
    <TreeItem
      itemId={category.id}
      ref={ref}
      style={{ opacity }}
      onFocusCapture={(e) => {
        e.stopPropagation()
      }}
      label={
        <Stack
          direction="row"
          width="100%"
          minHeight={14}
          onMouseEnter={() => {
            setCurrentHoverItem(pathsString)
          }}
          onMouseLeave={() => {
            setCurrentHoverItem(null)
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1} flex={1}>
            <Typography
              variant="body1"
              color={category.active ? 'inherit' : 'text.disabled'}
            >
              {getLocalizedContent(category.names)}
            </Typography>

            {category.items &&
              category.items.length > 0 &&
              category.items.map((item) => (
                <Tooltip key={item.id} title={getLocalizedContent(item.names)}>
                  <Avatar
                    alt={getLocalizedContent(item.names)}
                    src={item.logoUrl}
                    sx={{ width: 18, height: 18 }}
                  />
                </Tooltip>
              ))}

            {!category.resourcesAllowed && (
              <Tooltip
                title={formatMessage({
                  id: 'category_edit.tooltip.add_image_for_report_disabled',
                })}
              >
                <HideImageIcon
                  fontSize="inherit"
                  aria-label={formatMessage({
                    id: 'category_edit.tooltip.add_image_for_report_disabled',
                  })}
                />
              </Tooltip>
            )}
            {!category.locationRequired && (
              <Tooltip
                title={formatMessage({
                  id: 'category_edit.tooltip.add_location_for_report_disabled',
                })}
              >
                <HideLocationIcon
                  fontSize="inherit"
                  aria-label={formatMessage({
                    id: 'category_edit.tooltip.add_location_for_report_disabled',
                  })}
                />
              </Tooltip>
            )}
          </Stack>
          {currentHoverItem === pathsString && (
            <Stack direction={'row'} spacing={1}>
              <Tooltip
                title={formatMessage({
                  id: 'general.icon_button.edit',
                })}
              >
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation()
                    onEditCategory(paths, category)
                  }}
                  aria-label={formatMessage({
                    id: 'general.icon_button.edit',
                  })}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={formatMessage({
                  id: category.locationRequired
                    ? 'category_edit.tooltip.disable_add_location_for_report'
                    : 'category_edit.tooltip.enable_add_location_for_report',
                })}
              >
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation()
                    onToggleLocationRequired(paths, !category.locationRequired)
                  }}
                  aria-label={formatMessage({
                    id: category.locationRequired
                      ? 'category_edit.tooltip.disable_add_location_for_report'
                      : 'category_edit.tooltip.enable_add_location_for_report',
                  })}
                >
                  {category.locationRequired ? (
                    <HideLocationIcon fontSize="inherit" />
                  ) : (
                    <ShowLocationIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip
                title={formatMessage({
                  id: category.resourcesAllowed
                    ? 'category_edit.tooltip.disable_add_image_for_report'
                    : 'category_edit.tooltip.enable_add_image_for_report',
                })}
              >
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation()
                    onToggleResourcesAllowed(paths, !category.resourcesAllowed)
                  }}
                  aria-label={formatMessage({
                    id: category.resourcesAllowed
                      ? 'category_edit.tooltip.disable_add_image_for_report'
                      : 'category_edit.tooltip.enable_add_image_for_report',
                  })}
                >
                  {category.resourcesAllowed ? (
                    <HideImageIcon fontSize="inherit" />
                  ) : (
                    <ShowImageIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip
                title={formatMessage({
                  id: category.active
                    ? 'edit_categories.button.hide_category'
                    : 'edit_categories.button.show_category',
                })}
              >
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation()
                    onToggleCategory(paths, !category.active)
                  }}
                  aria-label={formatMessage({
                    id: category.active
                      ? 'edit_categories.button.hide_category'
                      : 'edit_categories.button.show_category',
                  })}
                >
                  {category.active ? (
                    <InactivateIcon fontSize="inherit" />
                  ) : (
                    <ActivateIcon fontSize="inherit" />
                  )}
                </IconButton>
              </Tooltip>

              <Tooltip
                title={formatMessage({
                  id: 'general.icon_button.delete',
                })}
              >
                <IconButton
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation()
                    onDeleteCategory(paths)
                  }}
                  aria-label={formatMessage({
                    id: 'general.icon_button.delete',
                  })}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </Stack>
      }
    >
      {paths.length < CATEGORY_MAX_DEPTH && (
        <SubCategoryEditTree
          paths={paths}
          categories={category.subcategories ?? []}
          onAddCategory={onAddCategory}
          onToggleCategory={onToggleCategory}
          onToggleResourcesAllowed={onToggleResourcesAllowed}
          onToggleLocationRequired={onToggleLocationRequired}
          onDeleteCategory={onDeleteCategory}
          onEditCategory={onEditCategory}
          currentHoverItem={currentHoverItem}
          setCurrentHoverItem={setCurrentHoverItem}
          onMoveCategory={onMoveCategory}
        />
      )}
    </TreeItem>
  )
}

export default CategoryEditItem
