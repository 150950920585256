import { useRef } from 'react'
import { useIntl } from 'react-intl'
import { Tooltip, Stack, Box, Typography } from '@mui/material'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import HideImageIcon from '@mui/icons-material/HideImageOutlined'
import HideLocationIcon from '@mui/icons-material/LocationOffOutlined'
import InactivateIcon from '@mui/icons-material/VisibilityOffOutlined'

import { type Category } from '../categoryTypes'
import SubCategoryListTree from './SubCategoryListTree'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'
import usePortalSetting from 'hooks/usePortalSetting'
import {
  CollapseIcon,
  ExpandIcon,
} from 'components/category/CategoryStyledComponents'

type CategoryListItemProps = {
  paths: string[]
  category: Category
}

const CategoryListItem: React.FC<CategoryListItemProps> = ({
  paths,
  category,
}) => {
  const ref = useRef<HTMLLIElement>(null)
  const { formatMessage } = useIntl()
  const { getLocalizedContent } = usePortalSetting()
  const clonePaths = [...paths]
  clonePaths.pop()

  return (
    <TreeItem
      itemId={category.id}
      ref={ref}
      onFocusCapture={(e) => {
        e.stopPropagation()
      }}
      label={
        <Stack direction="row" width="100%">
          <Box flexGrow={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography
                variant="body1"
                color={category.active ? 'inherit' : 'text.disabled'}
              >
                {getLocalizedContent(category.names)}
              </Typography>

              {!category.resourcesAllowed && (
                <Tooltip
                  title={formatMessage({
                    id: 'category_edit.tooltip.add_image_for_report_disabled',
                  })}
                >
                  <HideImageIcon
                    fontSize="inherit"
                    aria-label={formatMessage({
                      id: 'category_edit.tooltip.add_image_for_report_disabled',
                    })}
                  />
                </Tooltip>
              )}
              {!category.locationRequired && (
                <Tooltip
                  title={formatMessage({
                    id: 'category_edit.tooltip.add_location_for_report_disabled',
                  })}
                >
                  <HideLocationIcon
                    fontSize="inherit"
                    aria-label={formatMessage({
                      id: 'category_edit.tooltip.add_location_for_report_disabled',
                    })}
                  />
                </Tooltip>
              )}
              {!category.active && (
                <Tooltip
                  title={formatMessage({
                    id: 'category_edit.tooltip.category_not_active',
                  })}
                >
                  <InactivateIcon
                    fontSize="inherit"
                    aria-label={formatMessage({
                      id: 'category_edit.tooltip.category_not_active',
                    })}
                  />
                </Tooltip>
              )}
            </Stack>
          </Box>
        </Stack>
      }
      slots={{
        collapseIcon:
          category.subcategories && category.subcategories.length > 0
            ? ExpandIcon
            : undefined,
        expandIcon:
          category.subcategories && category.subcategories.length > 0
            ? CollapseIcon
            : undefined,
      }}
    >
      {paths.length < CATEGORY_MAX_DEPTH && (
        <SubCategoryListTree
          paths={paths}
          categories={category.subcategories ?? []}
        />
      )}
    </TreeItem>
  )
}

export default CategoryListItem
