import { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { useForm, Controller } from 'react-hook-form'
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  IconButton,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import { sortedSupportLanguagesSelector } from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { type Locale } from 'types'
import { type ItemBasic } from 'components/item/itemTypes'
import usePortalSetting from 'hooks/usePortalSetting'
import { CATEGORY_NAME_MAX_LENGTH } from 'commonConstants'
import { type CategoryFormData } from 'components/category/categoryTypes'
import { convertTranslationKeysToUpperCase } from 'utils/categoryUtils'
import { convertLocalizedStringToData } from 'utils/stringUtils'

type CategoryEditDialogProps = {
  isOpen: boolean
  title: string
  items: ItemBasic[]
  selectedCategory?: CategoryFormData | null
  onSave: (itemIds: string[], names: Record<Locale, string>) => void
  onClose: () => void
}

type CategoryEditFormData = {
  translations: Record<Locale, string>
  itemIds: string[]
}

const CategoryEditDialog: React.FC<CategoryEditDialogProps> = ({
  isOpen,
  title,
  items,
  selectedCategory,
  onSave,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { getLocalizedContent } = usePortalSetting()
  const {
    control,
    getValues,
    setValue,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm<CategoryEditFormData>({
    mode: 'onTouched',
    defaultValues: {
      translations: {},
      itemIds: ['-1'],
    },
  })

  const itemIds = watch('itemIds')

  useEffect(() => {
    if (selectedCategory?.names) {
      const translations = convertTranslationKeysToUpperCase(
        convertLocalizedStringToData(selectedCategory.names),
      )
      const keys = Object.keys(translations)
      keys.forEach((key) => {
        setValue(`translations.${key}`, translations[key])
      })
    }

    if (selectedCategory?.items && selectedCategory?.items.length > 0) {
      setValue(
        'itemIds',
        selectedCategory?.items.map((item) => item.id),
      )
    }
  }, [selectedCategory])

  const handleSave = (): void => {
    const translations = getValues('translations')
    const itemIds = getValues('itemIds')

    onSave(itemIds, translations)
    reset()
  }

  const handleClose = (): void => {
    onClose()
    reset()
  }

  const getErrorMessage = useCallback(
    (language: string) => {
      if (errors?.translations?.[language]?.type === 'maxLength') {
        return formatMessage(
          {
            id: 'general.error.max_length',
          },
          { max: CATEGORY_NAME_MAX_LENGTH },
        )
      }

      if (errors?.translations?.[language]?.type === 'required') {
        return formatMessage(
          {
            id: 'general.error.required',
          },
          { max: CATEGORY_NAME_MAX_LENGTH },
        )
      }

      return ''
    },
    [errors],
  )

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>{title}</DialogTitle>
      <IconButton
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack width="100%" spacing={1}>
          <FormControl fullWidth error={!!errors.itemIds}>
            <InputLabel id="items-label" size="small" shrink>
              {formatMessage({
                id: 'category_edit.label.items_visibility',
              })}
            </InputLabel>
            <Controller
              name="itemIds"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  multiple
                  labelId="items-label"
                  label={formatMessage({
                    id: 'category_edit.label.items_visibility',
                  })}
                  defaultValue={['-1']}
                  input={
                    <OutlinedInput
                      label={formatMessage({
                        id: 'category_edit.label.items_visibility',
                      })}
                    />
                  }
                  onChange={(e) => {
                    const selectedIds = e.target.value

                    if (
                      selectedIds[selectedIds.length - 1] === '-1' ||
                      selectedIds.length === 0
                    ) {
                      setValue('itemIds', ['-1'])
                    } else if (Array.isArray(selectedIds)) {
                      setValue(
                        'itemIds',
                        selectedIds.filter((id) => id !== '-1'),
                      )
                    }
                  }}
                  size="small"
                  fullWidth
                  variant="outlined"
                  renderValue={(selected) => {
                    if (selected[0] === '-1') {
                      return formatMessage({
                        id: 'category_edit.option.all_items',
                      })
                    }

                    return (
                      <Box
                        sx={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          gap: 0.5,
                        }}
                      >
                        {selected.map((value) => {
                          const selectedItem = items?.find(
                            (item) => item.id === value,
                          )

                          return (
                            <Chip
                              key={value}
                              size="small"
                              label={getLocalizedContent(selectedItem?.names)}
                            />
                          )
                        })}
                      </Box>
                    )
                  }}
                >
                  <MenuItem value="-1">
                    <Checkbox
                      checked={itemIds?.length === 1 && itemIds?.[0] === '-1'}
                    />
                    <ListItemText
                      primary={formatMessage({
                        id: 'category_edit.option.all_items',
                      })}
                    />
                  </MenuItem>

                  {items?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox checked={itemIds?.includes(item.id)} />
                      <ListItemText primary={getLocalizedContent(item.names)} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>

          {supportLanguages.map((language) => (
            <FormControl
              key={language}
              error={!!errors?.translations?.[language]}
            >
              <Box>
                <Chip label={language} color="info" size="small"></Chip>
              </Box>

              <Controller
                name={`translations.${language}`}
                control={control}
                rules={{
                  maxLength: CATEGORY_NAME_MAX_LENGTH,
                  required: true,
                }}
                defaultValue={''}
                render={({ field }) => (
                  <MultiLineInput
                    {...field}
                    error={!!errors?.translations?.[language]}
                    maxLength={CATEGORY_NAME_MAX_LENGTH}
                    rows={1}
                    variant="outlined"
                    fullWidth
                    helpMessage={getErrorMessage(language)}
                  />
                )}
              />
            </FormControl>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          fullWidth
          onClick={handleSave}
          variant="contained"
          disabled={!isValid || Object.keys(errors).length > 0}
        >
          {formatMessage({ id: 'general.button.save' })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CategoryEditDialog
