import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useForm, Controller, useFieldArray } from 'react-hook-form'
import { useParams, useSearchParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import {
  headingsPlugin,
  listsPlugin,
  linkPlugin,
  linkDialogPlugin,
  quotePlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  toolbarPlugin,
  BlockTypeSelect,
  ListsToggle,
  CreateLink,
  type MDXEditorMethods,
} from '@mdxeditor/editor'
import '@mdxeditor/editor/style.css'
import {
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  TextField,
  InputAdornment,
  FormHelperText,
  Divider,
  InputLabel,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  FormLabel,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import EditIcon from '@mui/icons-material/Edit'
import ItemIcon from '@mui/icons-material/FolderCopy'
import AddIcon from '@mui/icons-material/Add'
import ChecklistIcon from '@mui/icons-material/Checklist'
import HourglassBottomRoundedIcon from '@mui/icons-material/HourglassBottomRounded'
import OpenIcon from '@mui/icons-material/Public'
import RestrictedIcon from '@mui/icons-material/Lock'

import {
  MainHeader,
  BackButtonGrey,
  SettingWrapper,
  LightSubHeader,
  SubSubHeader,
  FormFieldsWrapper,
  SelectBox,
  MDEditor,
} from 'components/StyledComponents'
import useRoute from 'hooks/useNavigate'
import { Path, ThemeMode } from '../commonConstants'

import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import MultiLineInput from 'components/form/MultiLineInput'
import { hasUnsavedChangesState } from 'state/formStates'
import TranslationDialog from 'components/helpers/TranslationDialog'
import useApi from 'hooks/useApi'
import {
  type Poll,
  type PollAnswer,
  type PollPayload,
  type PollFormData,
  type PollFormAnswer,
} from 'components/poll/pollTypes'
import {
  POLL_ANSWER_MAX_LENGTH,
  POLL_DESCRIPTION_MAX_LENGTH,
  POLL_QUESTION_MAX_LENGTH,
  PollResultExposure,
  PollVisibility,
} from 'components/poll/pollConstants'
import { type ItemBasic } from 'components/item/itemTypes'
import LogoWrapper from 'components/LogoWrapper'
import usePortalSetting from 'hooks/usePortalSetting'
import DateInput from 'components/form/DateInput'
import {
  ExposureAlwaysIcon,
  ExposureAfterIcon,
  ExposureNeverIcon,
} from 'components/icons/Icons'
import {
  convertDataToLocalizedString,
  convertLocalizedStringToData,
} from 'utils/stringUtils'
import { themeModeState } from 'state/layoutStates'
import PollEditAnswer from 'components/poll/PollEditAnswer'
import { nanoid } from 'nanoid'
import PollPreviousDialog from 'components/poll/PollPreviewDialog'

const MAX_OPTIONS_LENGTH = 12

const PollEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { pollId } = useParams()
  const [searchParams] = useSearchParams()
  const isCopy = searchParams.get('isCopy')
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const { sendPostRequest, sendPutRequest } = useApi()
  const { enqueueSnackbar } = useSnackbar()
  const [isSaving, setIsSaving] = useState(false)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isTranslationDialogOpen, setIsTranslationDialogOpen] = useState(false)
  const [translationDialogTitle, setTranslationDialogTitle] = useState('')
  const [translationInputRows, setTranslationInputRows] = useState(3)
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const translationContext = useRef<
    'titles' | 'descriptions' | 'questions' | `answers.${number}.texts` | null
  >(null)
  const [maxTranslationLength, setMaxTranslationLength] = useState(
    POLL_QUESTION_MAX_LENGTH,
  )
  const [items, setItems] = useState<ItemBasic[]>([])
  const [isLoadingItems, setIsLoadingItems] = useState(false)
  const [isExpiryDisabled, setIsExpiryDisabled] = useState(true)
  const { getLocalizedContent } = usePortalSetting()
  const [isActiveConfirmDialogOpen, setIsActiveConfirmDialogOpen] =
    useState(false)
  const editorRef = useRef<MDXEditorMethods>(null)
  const themeMode = useRecoilValue(themeModeState)
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)
  const [isRichEditor, setIsRichEditor] = useState(false)
  const { data: pollData, isLoading } = useSWR<Poll>(
    portalSetting && pollId
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/polls/${pollId}`
      : null,
  )
  const {
    control,
    setValue,
    formState: { errors, isValid, isDirty, dirtyFields },
    getValues,
    trigger,
    watch,
  } = useForm<PollFormData>({
    mode: 'onTouched',
    defaultValues: {
      questions: {},
      visibility: PollVisibility.OPEN,
      resultConfiguration: {
        exposure: PollResultExposure.ALWAYS,
        retentionDays: 1,
      },
    },
  })

  const {
    fields: answersFields,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: 'answers',
  })

  const descriptions = watch('descriptions')
  const questions = watch('questions')
  const answers = watch('answers')
  const visibility = watch('visibility')
  const resultConfiguration = watch('resultConfiguration')

  useEffect(() => {
    if (pollData) {
      setValue('titles', convertLocalizedStringToData(pollData.titles))
      setValue(
        'descriptions',
        convertLocalizedStringToData(pollData.descriptions),
      )
      setValue(
        'questions',
        convertLocalizedStringToData(pollData.question.texts),
      )
      setValue('itemId', pollData.item.id)

      if (pollData.question.answers) {
        const answers: PollFormAnswer[] = []
        pollData.question.answers.forEach((answer) => {
          answers.push({
            id: nanoid(),
            index: answer.index,
            link: answer.link,
            texts: convertLocalizedStringToData(answer.texts),
          })
        })
        setValue(
          'answers',
          answers.sort((a, b) => a.index - b.index),
        )
      }

      if (pollData.expiry && !isCopy) {
        setValue('expiry', dayjs(pollData.expiry))
        setIsExpiryDisabled(false)
      }

      setValue('multichoice', pollData.question.multichoice)
      setValue('visibility', pollData.visibility)
      setValue('resultConfiguration', pollData.result.configuration)
    }
  }, [pollData, isCopy])

  useEffect(() => {
    if (
      resultConfiguration.exposure === PollResultExposure.NEVER &&
      errors.resultConfiguration?.retentionDays
    ) {
      setValue('resultConfiguration.retentionDays', 1, {
        shouldValidate: true,
      })
    }
  }, [resultConfiguration.exposure, errors])

  useEffect(() => {
    if (
      descriptions &&
      editorRef.current &&
      portalSetting &&
      descriptions[portalSetting.defaultLanguage]
    ) {
      editorRef.current.setMarkdown(descriptions[portalSetting.defaultLanguage])
    }
  }, [descriptions, editorRef.current, portalSetting])

  const getItems = useCallback(async (): Promise<void> => {
    if (!portalSetting) {
      return
    }

    setIsLoadingItems(true)
    const response = await sendPostRequest(
      `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
        portalSetting?.id
      }/polls:listAssignableItems`,
    )
    const itemsData = await response.json()
    setItems(itemsData)

    setIsLoadingItems(false)
  }, [portalSetting])

  const addOption = useCallback((): void => {
    if (answersFields?.length < MAX_OPTIONS_LENGTH && portalSetting) {
      append({
        id: nanoid(),
        index: answersFields.length,
        texts: { [portalSetting.defaultLanguage]: '' },
      })
    }
  }, [answersFields, portalSetting])

  useEffect(() => {
    if (portalSetting) {
      void getItems()

      if (!pollId) {
        setValue('answers', [
          {
            id: nanoid(),
            index: 0,
            texts: { [portalSetting.defaultLanguage]: '' },
          },
          {
            id: nanoid(),
            index: 1,
            texts: { [portalSetting.defaultLanguage]: '' },
          },
        ])
      }
    }
  }, [portalSetting, pollId])

  useEffect(() => {
    setHasUnsavedChanges(isDirty)
  }, [isDirty])

  const addOrUpdatePoll = useCallback(
    async (data: PollFormData, isActive: boolean): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        setIsSaving(true)
        const answers: PollAnswer[] = []
        if (data.answers.length) {
          data.answers.forEach((answer, index) => {
            answers.push({
              index,
              texts: convertDataToLocalizedString(answer.texts),
              link: answer.link,
            })
          })
        }
        const formData: PollPayload = {
          itemId: data.itemId,
          titles: convertDataToLocalizedString(data.titles),
          descriptions: convertDataToLocalizedString(data.descriptions),
          question: {
            texts: convertDataToLocalizedString(data.questions),
            answers,
            multichoice: data.multichoice,
          },
          visibility: data.visibility,
          active: isActive,
          resultConfiguration: data.resultConfiguration,
        }

        if (data.expiry) {
          formData.expiry = data.expiry.format()
        }

        if (pollId && !isCopy) {
          await sendPutRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/polls/${pollId}`,
            formData,
          )
        } else {
          await sendPostRequest(
            `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
              portalSetting?.id
            }/polls`,
            formData,
          )
        }

        setHasUnsavedChanges(false)
        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        goTo(Path.POLLS_LIST, true)
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
      }
    },
    [portalSetting, pollId],
  )

  const handleGoBack = (): void => {
    goTo(Path.POLLS_LIST)
  }

  const handleOpenQuestionTranslationDialog = useCallback((): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = 'questions'
    setMaxTranslationLength(POLL_QUESTION_MAX_LENGTH)
    setIsRichEditor(false)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }, [])

  const handleOpenOptionTranslationDialog = useCallback(
    (index: number): void => {
      setIsTranslationDialogOpen(true)
      translationContext.current = `answers.${index}.texts`
      setMaxTranslationLength(POLL_ANSWER_MAX_LENGTH)
      setIsRichEditor(false)
      setTranslationDialogTitle(
        formatMessage({
          id: 'portal_item_edit.button.manage_localization',
        }),
      )
      setTranslationInputRows(1)
    },
    [],
  )

  const handleOpenTitleTranslationDialog = useCallback((): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = `titles`
    setMaxTranslationLength(POLL_ANSWER_MAX_LENGTH)
    setIsRichEditor(false)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(1)
  }, [])

  const handleOpenDescriptionsTranslationDialog = useCallback((): void => {
    setIsTranslationDialogOpen(true)
    translationContext.current = `descriptions`
    setMaxTranslationLength(POLL_DESCRIPTION_MAX_LENGTH)
    setIsRichEditor(true)
    setTranslationDialogTitle(
      formatMessage({
        id: 'portal_item_edit.button.manage_localization',
      }),
    )
    setTranslationInputRows(3)
  }, [])

  const handleSaveTranslation = useCallback(
    (data: Record<string, string>): void => {
      if (translationContext.current) {
        setValue(translationContext.current, data, {
          shouldValidate: true,
          shouldTouch: true,
          shouldDirty: true,
        })
        void trigger(translationContext.current)
      }
      setIsTranslationDialogOpen(false)
    },
    [],
  )

  const handleCloseTranslationDialog = useCallback((): void => {
    setIsTranslationDialogOpen(false)
  }, [])

  const getTranslationDefaultLanguageValue = useCallback((): Record<
    string,
    string
  > => {
    if (translationContext.current) {
      return getValues(translationContext.current)
    }

    return {}
  }, [translationContext.current])

  const validateTranslations = useCallback(
    (
      name: 'titles' | 'descriptions' | 'questions' | `answers.${number}.texts`,
    ): null | string => {
      if (!portalSetting) {
        return null
      }

      const data = getValues(name)

      let missingTranslations: string[] = [...portalSetting.supportedLanguages]

      if (data) {
        const keys = Object.keys(data)
        keys.forEach((key) => {
          if (data[key]) {
            missingTranslations = missingTranslations.filter(
              (item) => item !== key,
            )
          }
        })

        if (missingTranslations.length) {
          return missingTranslations.join(', ')
        }
      }

      return null
    },
    [portalSetting],
  )

  const missingQuestionsTranslation = useMemo(
    () => !!dirtyFields.questions && !!validateTranslations('questions'),
    [questions],
  )

  const shouldDisableSaving = useMemo(
    () =>
      !isValid ||
      isLoadingItems ||
      isSaving ||
      missingQuestionsTranslation ||
      answers?.length < 2,
    [
      isValid,
      isLoadingItems,
      isSaving,
      portalSetting?.type,
      missingQuestionsTranslation,
      answers,
    ],
  )

  const handleToggleExpirationDate = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setValue('expiry', null)
    setIsExpiryDisabled(!event.target.checked)
  }

  const handleSaveAsActive = (): void => {
    const formData = getValues()
    void addOrUpdatePoll(formData, true)
  }

  const handleSaveAsDraft = (): void => {
    const formData = getValues()
    void addOrUpdatePoll(formData, false)
  }

  const handleCloseActiveConfirmDialog = (): void => {
    setIsActiveConfirmDialogOpen(false)
  }

  const handleSetActive = (): void => {
    setIsActiveConfirmDialogOpen(true)
  }

  const handleMoveAnswer = (dragIndex: number, hoverIndex: number): void => {
    swap(dragIndex, hoverIndex)
  }

  const handleRemoveAnswer = (index: number): void => {
    remove(index)
  }

  const handleOpenPreviewDialog = (): void => {
    setIsPreviewDialogOpen(true)
  }

  const handleClosePreviewDialog = (): void => {
    setIsPreviewDialogOpen(false)
  }

  if ((pollId && isLoading) || !portalSetting) {
    return <CircularProgress />
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack height={'100%'} width={'100%'}>
        <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
          <Stack flexGrow={1} direction="row" spacing={1}>
            <BackButtonGrey
              onClick={handleGoBack}
              size="small"
              aria-label={formatMessage({
                id: 'general.icon_button.go_back',
              })}
            >
              <ArrowBackIcon />
            </BackButtonGrey>

            <MainHeader>
              {pollId && !isCopy && formatMessage({ id: 'poll_edit.header' })}
              {pollId && isCopy && formatMessage({ id: 'poll_copy.header' })}
              {!pollId && formatMessage({ id: 'poll_add.header' })}
            </MainHeader>
          </Stack>

          <Button size="small" onClick={handleOpenPreviewDialog}>
            {formatMessage({ id: 'news_edit.button.preview' })}
          </Button>

          {(!pollId || pollData?._operations.canActivate || isCopy) && (
            <LoadingButton
              variant="contained"
              size="small"
              type="submit"
              disabled={shouldDisableSaving}
              loading={isSaving}
              color="secondary"
              onClick={handleSetActive}
            >
              {formatMessage({ id: 'poll_edit.button.activate' })}
            </LoadingButton>
          )}

          <LoadingButton
            variant="outlined"
            size="small"
            type="submit"
            disabled={shouldDisableSaving}
            loading={isSaving}
            onClick={handleSaveAsDraft}
          >
            {formatMessage({
              id:
                pollId && !isCopy
                  ? 'general.button.save'
                  : 'poll_edit.button.save_as_draft',
            })}
          </LoadingButton>
        </Stack>

        <SettingWrapper width="100%" flexGrow={1}>
          <Stack paddingY={2} paddingX={4} spacing={2}>
            <LightSubHeader>
              {formatMessage({
                id: 'poll_edit.subheader.poll_details',
              })}
            </LightSubHeader>

            <Stack spacing={2}>
              <Box>
                {supportLanguages.length > 1 && (
                  <Stack direction="row" justifyContent="end">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenTitleTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_localization',
                      })}
                    </Button>
                  </Stack>
                )}

                <Controller
                  name={`titles.${portalSetting.defaultLanguage}`}
                  control={control}
                  rules={{
                    maxLength: POLL_QUESTION_MAX_LENGTH,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      error={!!errors.titles}
                      size="small"
                      label={`${formatMessage({
                        id: 'poll_edit.label.title',
                      })}  ${
                        supportLanguages.length < 2
                          ? ''
                          : ` (${portalSetting.defaultLanguage})`
                      }`}
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
                {errors.titles?.[portalSetting.defaultLanguage]?.type ===
                  'maxLength' && (
                  <FormHelperText error>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: POLL_QUESTION_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}

                {!!dirtyFields.titles &&
                  Object.keys(dirtyFields.titles).length > 0 &&
                  !!validateTranslations('titles') && (
                    <FormHelperText error>
                      {formatMessage(
                        {
                          id: 'portal_item_edit.error.missing_translations',
                        },
                        {
                          missingTranslations: validateTranslations('titles'),
                        },
                      )}
                    </FormHelperText>
                  )}
              </Box>
            </Stack>

            <Stack spacing={2}>
              <Box>
                {supportLanguages.length > 1 && (
                  <Stack direction="row" alignItems="center">
                    <FormLabel sx={{ flexGrow: 1, fontSize: 14 }}>
                      {formatMessage({
                        id: 'poll_edit.label.description',
                      })}
                    </FormLabel>
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenDescriptionsTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_localization',
                      })}
                    </Button>
                  </Stack>
                )}

                <Controller
                  name={`descriptions.${portalSetting.defaultLanguage}`}
                  control={control}
                  render={({ field }) => (
                    <MDEditor
                      ref={editorRef}
                      className={
                        themeMode === ThemeMode.DARK ? 'dark-theme' : ''
                      }
                      markdown=""
                      suppressHtmlProcessing={true}
                      onChange={(markdown) => {
                        field.onChange(markdown.replaceAll('&#x20;', ' '))
                      }}
                      plugins={[
                        headingsPlugin(),
                        listsPlugin(),
                        linkPlugin(),
                        linkDialogPlugin(),
                        quotePlugin(),
                        toolbarPlugin({
                          toolbarContents: () => (
                            <>
                              {' '}
                              <UndoRedo />
                              <BlockTypeSelect />
                              <BoldItalicUnderlineToggles
                                options={['Bold', 'Italic']}
                              />
                              <ListsToggle options={['bullet', 'number']} />
                              <CreateLink />
                            </>
                          ),
                        }),
                      ]}
                    />
                  )}
                />
                {errors.descriptions?.[portalSetting.defaultLanguage]?.type ===
                  'maxLength' && (
                  <FormHelperText error>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: POLL_QUESTION_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}

                {!!dirtyFields.descriptions &&
                  Object.keys(dirtyFields.descriptions).length > 0 &&
                  !!validateTranslations('descriptions') && (
                    <FormHelperText error>
                      {formatMessage(
                        {
                          id: 'portal_item_edit.error.missing_translations',
                        },
                        {
                          missingTranslations:
                            validateTranslations('descriptions'),
                        },
                      )}
                    </FormHelperText>
                  )}
              </Box>
            </Stack>

            <Stack spacing={2}>
              <Box>
                {supportLanguages.length > 1 && (
                  <Stack direction="row" justifyContent="end">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      onClick={handleOpenQuestionTranslationDialog}
                    >
                      {formatMessage({
                        id: 'portal_item_edit.button.manage_localization',
                      })}
                    </Button>
                  </Stack>
                )}

                <Controller
                  name={`questions.${portalSetting.defaultLanguage}`}
                  control={control}
                  rules={{
                    maxLength: POLL_QUESTION_MAX_LENGTH,
                    required: true,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <MultiLineInput
                      {...field}
                      required
                      maxLength={POLL_QUESTION_MAX_LENGTH}
                      error={
                        !!errors?.questions?.[portalSetting.defaultLanguage]
                      }
                      label={`${formatMessage({
                        id: 'poll_edit.label.question',
                      })}${
                        supportLanguages.length < 2
                          ? ''
                          : ` (${portalSetting.defaultLanguage})`
                      }`}
                      variant="outlined"
                      fullWidth
                      rows={3}
                    />
                  )}
                />
                {errors.questions?.[portalSetting.defaultLanguage]?.type ===
                  'maxLength' && (
                  <FormHelperText error>
                    {formatMessage(
                      {
                        id: 'general.error.max_length',
                      },
                      {
                        max: POLL_QUESTION_MAX_LENGTH,
                      },
                    )}
                  </FormHelperText>
                )}

                {!!dirtyFields.questions &&
                  !!validateTranslations('questions') && (
                    <FormHelperText error>
                      {formatMessage(
                        {
                          id: 'portal_item_edit.error.missing_translations',
                        },
                        {
                          missingTranslations:
                            validateTranslations('questions'),
                        },
                      )}
                    </FormHelperText>
                  )}
              </Box>

              <Stack width="100%" spacing={1}>
                <SubSubHeader>
                  {formatMessage({
                    id: 'poll_edit.subheader.answers',
                  })}
                </SubSubHeader>
                <FormFieldsWrapper>
                  {answersFields?.map((answer, index) => (
                    <PollEditAnswer
                      key={answer.id}
                      answer={answer}
                      index={index}
                      control={control}
                      onOpenOptionTranslationDialog={
                        handleOpenOptionTranslationDialog
                      }
                      validateTranslations={validateTranslations}
                      remove={handleRemoveAnswer}
                      defaultLanguage={portalSetting.defaultLanguage}
                      onMoveAnswer={handleMoveAnswer}
                    />
                  ))}

                  <Box textAlign="right" sx={{ bg: 'white' }}>
                    <Button
                      variant="contained"
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={addOption}
                      disabled={answers?.length >= MAX_OPTIONS_LENGTH}
                    >
                      {formatMessage({ id: 'poll_edit.button.add_option' })}
                    </Button>
                  </Box>
                </FormFieldsWrapper>

                <FormFieldsWrapper>
                  <FormControlLabel
                    value="start"
                    disableTypography
                    control={
                      <Controller
                        name="multichoice"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => (
                          <Switch
                            {...field}
                            checked={field.value}
                            color="primary"
                            onChange={(e) => {
                              field.onChange(e)
                              setValue('multichoice', e.target.checked)
                            }}
                          />
                        )}
                      />
                    }
                    label={
                      <Stack direction="row" spacing={1} width="100%">
                        <ChecklistIcon />
                        <Typography>
                          {formatMessage({
                            id: 'poll_edit.label.multichoice',
                          })}
                        </Typography>
                      </Stack>
                    }
                    labelPlacement="start"
                  />
                </FormFieldsWrapper>

                <FormFieldsWrapper>
                  <FormControlLabel
                    value="start"
                    disableTypography
                    control={
                      <Switch
                        checked={!isExpiryDisabled}
                        onChange={handleToggleExpirationDate}
                        color="primary"
                      />
                    }
                    label={
                      <Stack direction="row" spacing={1} width="100%">
                        <HourglassBottomRoundedIcon />
                        <Typography>
                          {formatMessage({
                            id: 'poll_edit.label.expiry',
                          })}
                        </Typography>
                      </Stack>
                    }
                    labelPlacement="start"
                  />

                  <FormControl>
                    <Controller
                      name="expiry"
                      control={control}
                      render={({ field }) => (
                        <DateInput
                          {...field}
                          disabled={isExpiryDisabled}
                          label={formatMessage({
                            id: 'poll_edit.label.expiry',
                          })}
                          disablePast={true}
                          showTime={true}
                        />
                      )}
                    />
                  </FormControl>
                </FormFieldsWrapper>
              </Stack>
            </Stack>
          </Stack>

          <Divider />

          <Stack paddingY={2} paddingX={4} spacing={2}>
            <LightSubHeader>
              {formatMessage({
                id: 'poll_edit.subheader.visibility_in_app',
              })}
            </LightSubHeader>

            <Stack spacing={2} width="100%">
              <FormControl fullWidth error={!!errors.itemId}>
                <InputLabel id="item-label" size="small" required>
                  {formatMessage({
                    id: 'alert_edit.label.item',
                  })}
                </InputLabel>
                <Controller
                  name="itemId"
                  control={control}
                  rules={{
                    required: true,
                  }}
                  defaultValue={''}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="item-label"
                      label={formatMessage({
                        id: 'alert_edit.label.item',
                      })}
                      startAdornment={
                        <InputAdornment position="start">
                          <ItemIcon fontSize="small" color="primary" />
                        </InputAdornment>
                      }
                      size="small"
                      fullWidth
                      variant="outlined"
                    >
                      {items.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                          >
                            <LogoWrapper
                              size={22}
                              url={item.logoUrl}
                              alt={getLocalizedContent(item.names)}
                            ></LogoWrapper>
                            <Typography>
                              {getLocalizedContent(item.names)}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </FormControl>

              <Controller
                name="visibility"
                control={control}
                defaultValue={PollVisibility.OPEN}
                render={({ field }) => (
                  <Stack direction={'row'}>
                    <SelectBox
                      spacing={1}
                      direction={'row'}
                      alignItems="center"
                      selected={
                        visibility === PollVisibility.OPEN ? 'selected' : ''
                      }
                      onClick={() => {
                        setValue('visibility', PollVisibility.OPEN)
                      }}
                    >
                      <Stack flexGrow={1} direction="row" spacing={1}>
                        <Stack alignItems="center" justifyContent="center">
                          <OpenIcon fontSize="large" />
                        </Stack>
                        <Stack>
                          <SubSubHeader>
                            {formatMessage({
                              id: 'poll_edit.label.visibility.open',
                            })}
                          </SubSubHeader>
                          <Typography variant="caption">
                            {formatMessage({
                              id: 'poll_edit.description.visibility.open',
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SelectBox>
                    <SelectBox
                      spacing={1}
                      direction={'row'}
                      alignItems="center"
                      selected={
                        visibility === PollVisibility.RESTRICTED
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue('visibility', PollVisibility.RESTRICTED)
                      }}
                    >
                      <Stack flexGrow={1} direction="row" spacing={1}>
                        <Stack alignItems="center" justifyContent="center">
                          <RestrictedIcon fontSize="large" />
                        </Stack>
                        <Stack>
                          <SubSubHeader>
                            {formatMessage({
                              id: 'poll_edit.label.visibility.restricted',
                            })}
                          </SubSubHeader>
                          <Typography variant="caption">
                            {formatMessage({
                              id: 'poll_edit.description.visibility.restricted',
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </SelectBox>
                  </Stack>
                )}
              />

              <SubSubHeader>
                {formatMessage({
                  id: 'poll_edit.subheader.result_exposure',
                })}
              </SubSubHeader>
              <Controller
                name="resultConfiguration.exposure"
                control={control}
                defaultValue={PollResultExposure.ALWAYS}
                render={({ field }) => (
                  <Stack direction={'row'} spacing={0.1}>
                    <SelectBox
                      spacing={1}
                      alignItems="center"
                      textAlign="center"
                      selected={
                        resultConfiguration.exposure ===
                        PollResultExposure.ALWAYS
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue(
                          'resultConfiguration.exposure',
                          PollResultExposure.ALWAYS,
                        )
                      }}
                    >
                      <ExposureAlwaysIcon fontSize="small" />
                      <SubSubHeader>
                        {formatMessage({
                          id: 'poll_edit.label.exposure.always',
                        })}
                      </SubSubHeader>

                      <Typography variant="caption">
                        {formatMessage({
                          id: 'poll_edit.description.exposure.always',
                        })}
                      </Typography>
                    </SelectBox>
                    <SelectBox
                      spacing={1}
                      alignItems="center"
                      textAlign="center"
                      selected={
                        resultConfiguration.exposure ===
                        PollResultExposure.AFTER_POLL_END
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue(
                          'resultConfiguration.exposure',
                          PollResultExposure.AFTER_POLL_END,
                        )
                      }}
                    >
                      <ExposureAfterIcon fontSize="small" />
                      <SubSubHeader>
                        {formatMessage({
                          id: 'poll_edit.label.exposure.after_poll_end',
                        })}
                      </SubSubHeader>

                      <Typography variant="caption">
                        {formatMessage({
                          id: 'poll_edit.description.exposure.after_poll_end',
                        })}
                      </Typography>
                    </SelectBox>
                    <SelectBox
                      spacing={1}
                      alignItems="center"
                      textAlign="center"
                      selected={
                        resultConfiguration.exposure ===
                        PollResultExposure.NEVER
                          ? 'selected'
                          : ''
                      }
                      onClick={() => {
                        setValue(
                          'resultConfiguration.exposure',
                          PollResultExposure.NEVER,
                        )
                      }}
                    >
                      <ExposureNeverIcon fontSize="small" />
                      <SubSubHeader>
                        {formatMessage({
                          id: 'poll_edit.label.exposure.never',
                        })}
                      </SubSubHeader>

                      <Typography variant="caption">
                        {formatMessage({
                          id: 'poll_edit.description.exposure.never',
                        })}
                      </Typography>
                    </SelectBox>
                  </Stack>
                )}
              />

              {resultConfiguration.exposure !== PollResultExposure.NEVER && (
                <FormFieldsWrapper>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Box flexGrow={1}>
                      <Typography variant="body2">
                        {formatMessage({
                          id: 'poll_edit.description.retention_days',
                        })}
                      </Typography>
                    </Box>
                    <FormControl
                      error={!!errors.resultConfiguration?.retentionDays}
                    >
                      <Controller
                        name="resultConfiguration.retentionDays"
                        control={control}
                        rules={{
                          validate: (val): boolean => {
                            if (
                              !!val &&
                              !Number.isNaN(Number(val)) &&
                              val > 0
                            ) {
                              return true
                            }
                            return false
                          },
                        }}
                        defaultValue={1}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            required={true}
                            type="number"
                            error={!!errors.resultConfiguration?.retentionDays}
                            size="small"
                            InputProps={{
                              endAdornment: formatMessage({
                                id: 'portal_item_edit.visibility_period.days',
                              }),
                            }}
                            variant="outlined"
                          />
                        )}
                      />
                      {!!errors.resultConfiguration?.retentionDays && (
                        <FormHelperText>
                          {formatMessage({
                            id: 'portal_item_edit.visibility_period.days.error',
                          })}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Stack>
                </FormFieldsWrapper>
              )}
            </Stack>
          </Stack>
        </SettingWrapper>
      </Stack>

      <TranslationDialog
        isOpen={isTranslationDialogOpen}
        title={translationDialogTitle}
        rows={translationInputRows}
        defaultValue={getTranslationDefaultLanguageValue()}
        maxLength={maxTranslationLength}
        onSave={handleSaveTranslation}
        onClose={handleCloseTranslationDialog}
        isRichEditor={isRichEditor}
      />

      <Dialog open={isActiveConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'poll_list.dialog.active.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'poll_list.dialog.active.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={handleSaveAsActive}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'alert_list.button.set_active',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseActiveConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <PollPreviousDialog
        isOpen={isPreviewDialogOpen}
        onClose={handleClosePreviewDialog}
        poll={getValues()}
      />
    </DndProvider>
  )
}

export default PollEditPage
