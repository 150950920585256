import { type CategoryBasic } from 'components/category/categoryTypes'
import CategorySelectorItem from './CategorySelectorItem'
import { CATEGORY_MAX_DEPTH } from 'commonConstants'

type SubCategorySelectorTreeProps<T extends CategoryBasic> = {
  paths: string[]
  categories: T[]
  selectedItemId: string | null
  onCategoryChange: (ids: string, selectedCategory: T) => void
}

const SubCategorySelectorTree = <T extends CategoryBasic>({
  paths,
  categories,
  selectedItemId,
  onCategoryChange,
}: SubCategorySelectorTreeProps<T>): JSX.Element[] | null => {
  if (paths.length >= CATEGORY_MAX_DEPTH) {
    return null
  }

  return categories?.map((category, index) => (
    <CategorySelectorItem<T>
      key={category.id}
      paths={[...paths, category.id]}
      category={category}
      selectedItemId={selectedItemId}
      onCategoryChange={onCategoryChange}
    />
  ))
}

export default SubCategorySelectorTree
