import { type ReactElement, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import styled from '@mui/material/styles/styled'
import { Box, Button, CircularProgress, Stack } from '@mui/material'
import PostAddIcon from '@mui/icons-material/PostAdd'

import PublicTopBanner, {
  PublicView,
} from 'components/publicCase/PublicTopBanner'
import PublicFooter from 'components/publicCase/PublicFooter'
import { useRecoilValue } from 'recoil'
import { publicPortalInfoState } from 'state/portalSettingStates'
import PublicCaseListMap from 'components/publicCase/PublicCaseListMap'
import { type PublicCasesRawData } from 'components/publicCase/publicCaseTypes'
import PublicCaseAdd from 'components/publicCase/PublicCaseAdd'
import {
  publicCaseFilterInputsState,
  publicKeywordState,
  selectedPublicCaseCategoriesState,
  selectedPublicCaseStatusesState,
  selectedPublicPortalItemsState,
} from 'state/publicCaseListStates'
import { type CaseStatus } from 'components/case/caseConstants'
import EmptyReportIcon from 'assets/icons/empty_report.svg'
import { SubHeader } from 'components/StyledComponents'
import useURLState from 'hooks/useURLState'

interface TabPanelProps {
  id: string
  children?: React.ReactNode
  selected: boolean
}

const EmptyCaseListWrapper = styled(Stack)`
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing(4)};
  align-items: center;
  background: ${({ theme }) => theme.palette.background.paper};
`

const NoReportWrapper = styled(EmptyCaseListWrapper)`
  height: 50%;
  width: 50%;
  justify-content: center;
  margin: auto;
`

export function CustomTabPanel(props: TabPanelProps): ReactElement {
  const { children, selected, ...other } = props

  return (
    <Box role="tabpanel" hidden={!selected} {...other} height="100%">
      {children}
    </Box>
  )
}

const PublicCaseAddPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const [searchParams] = useSearchParams()
  const publicPortalInfo = useRecoilValue(publicPortalInfoState)
  const selectedPublicCaseStatuses = useRecoilValue(
    selectedPublicCaseStatusesState,
  )
  const keyword = useRecoilValue(publicKeywordState)
  const selectedPublicPortalItems = useRecoilValue(
    selectedPublicPortalItemsState,
  )
  const selectedPublicCaseCategories = useRecoilValue(
    selectedPublicCaseCategoriesState,
  )
  const [publicView, setPublicView] = useURLState<PublicView>(
    'publicView',
    PublicView.CASE_ADD,
  )
  const caseInputFields = useRecoilValue(publicCaseFilterInputsState)
  const portalId = searchParams.get('portalId')
  const {
    data: casesResponseData,
    isLoading,
    isValidating,
  } = useSWR<PublicCasesRawData>(
    portalId && publicView === PublicView.CASE_LIST
      ? `${
          process.env.REACT_APP_PUBLIC_API_PATH ?? ''
        }/portals/${portalId}/cases`
      : null,
  )

  const handleRefresh = (): void => {
    void mutate(
      `${
        process.env.REACT_APP_PUBLIC_API_PATH ?? ''
      }/portals/${portalId}/cases`,
    )
  }

  const handleChangeView = (
    event: React.SyntheticEvent,
    newView: PublicView,
  ): void => {
    setPublicView(newView)
  }

  const otherFeedback = useMemo(() => {
    return caseInputFields?.categoriesFilter?.find((category) =>
      category.names.find(
        (name) =>
          (name.language === 'FI' &&
            name.content.toLowerCase() === 'muu palaute') ||
          (name.language === 'EN' &&
            name.content.toLowerCase() === 'other feedback') ||
          (name.language === 'SV' &&
            name.content.toLowerCase() === 'annan feedback'),
      ),
    )
  }, [caseInputFields])

  const filteredCases = useMemo(() => {
    const allCases = (casesResponseData?.cases ?? []).concat()
    allCases.sort(
      (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime(),
    )

    return allCases.filter((caseData) => {
      if (keyword) {
        const keywordLower = keyword.toLowerCase()
        if (
          !caseData.title?.toLowerCase().includes(keywordLower) &&
          !caseData.description.toLowerCase().includes(keywordLower) &&
          !caseData.location?.address.toLowerCase().includes(keywordLower) &&
          !caseData.location?.country?.toLowerCase().includes(keywordLower) &&
          !caseData.location?.postalCode?.toLowerCase().includes(keywordLower)
        ) {
          return false
        }
      }

      if (
        selectedPublicCaseStatuses.length > 0 &&
        !selectedPublicCaseStatuses.includes(caseData.status as CaseStatus)
      ) {
        return false
      }

      if (
        selectedPublicPortalItems.length > 0 &&
        !selectedPublicPortalItems.includes(caseData.item.id)
      ) {
        return false
      }

      if (
        selectedPublicCaseCategories.length > 0 &&
        otherFeedback &&
        caseData.category &&
        caseData.category.uncategorized &&
        caseData.category.id !== otherFeedback.id &&
        selectedPublicCaseCategories.includes(otherFeedback.id)
      ) {
        return true
      }

      if (
        selectedPublicCaseCategories.length > 0 &&
        caseData.category &&
        !selectedPublicCaseCategories.includes(caseData.category?.id)
      ) {
        return false
      }

      return true
    })
  }, [
    casesResponseData,
    selectedPublicCaseStatuses,
    keyword,
    selectedPublicPortalItems,
    selectedPublicCaseCategories,
    otherFeedback,
  ])

  return (
    <Box height={'100vh'} width="100%">
      <Stack height={'100%'} width="100%" overflow="hidden">
        <Box>
          <PublicTopBanner
            onChangeView={handleChangeView}
            currentView={publicView}
          />
        </Box>
        <Box flexGrow={1} overflow="auto" padding={2}>
          <CustomTabPanel
            id="public-view-tabpanel-case-add"
            aria-labelledby="public-view-tab-case-add"
            selected={publicView === PublicView.CASE_ADD}
          >
            {portalId && (
              <PublicCaseAdd
                portalId={portalId}
                cases={casesResponseData?.cases}
              />
            )}
          </CustomTabPanel>
          <CustomTabPanel
            id="public-view-tabpanel-case-list"
            aria-labelledby="public-view-tab-case-list"
            selected={publicView === PublicView.CASE_LIST}
          >
            {publicPortalInfo &&
              portalId &&
              !!casesResponseData?.cases?.length && (
                <PublicCaseListMap
                  portalId={portalId}
                  caseListWidth={400}
                  isLoading={isLoading || isValidating}
                  cases={filteredCases}
                  onRefresh={handleRefresh}
                  region={publicPortalInfo.mapConfiguration?.region}
                  language={publicPortalInfo.defaultLanguage.toLowerCase()}
                  zoom={publicPortalInfo.mapConfiguration?.zoomLevel}
                  center={publicPortalInfo.mapConfiguration?.centre}
                />
              )}

            {publicPortalInfo &&
              portalId &&
              casesResponseData?.cases?.length === 0 && (
                <Stack
                  width="100%"
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <NoReportWrapper spacing={2}>
                    {isLoading && <CircularProgress />}

                    {!isLoading && (
                      <Stack spacing={2} textAlign="center">
                        <EmptyReportIcon />
                        <SubHeader>
                          {formatMessage({
                            id: 'public_case_list.label.no_reports',
                          })}
                        </SubHeader>
                        <Button
                          variant="contained"
                          startIcon={<PostAddIcon />}
                          size="small"
                          onClick={() => {
                            setPublicView(PublicView.CASE_ADD)
                          }}
                          color="secondary"
                        >
                          {formatMessage({ id: 'consumer_page.button.report' })}
                        </Button>
                      </Stack>
                    )}
                  </NoReportWrapper>
                </Stack>
              )}
          </CustomTabPanel>
        </Box>
        <PublicFooter />
      </Stack>
    </Box>
  )
}

export default PublicCaseAddPage
