export const POLL_QUESTION_MAX_LENGTH = 1000
export const POLL_ANSWER_MAX_LENGTH = 500
export const POLL_DESCRIPTION_MAX_LENGTH = 3000

export enum PollVisibility {
  OPEN = 'OPEN',
  RESTRICTED = 'RESTRICTED',
}

export enum PollResultExposure {
  ALWAYS = 'ALWAYS',
  AFTER_POLL_END = 'AFTER_POLL_END',
  NEVER = 'NEVER',
}

export enum PollCategory {
  ONGOING = 'ONGOING',
  ENDED = 'ENDED',
  DRAFT = 'DRAFT',
  ARCHIVED = 'ARCHIVED',
}
