import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import { type Control, useController } from 'react-hook-form'
import { type XYCoord, useDrag, useDrop } from 'react-dnd'
import { sortedSupportLanguagesSelector } from 'state/portalSettingStates'
import {
  Stack,
  Button,
  FormControl,
  TextField,
  Box,
  IconButton,
  FormHelperText,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'

import { POLL_ANSWER_MAX_LENGTH } from 'components/poll/pollConstants'
import {
  type PollFormAnswer,
  type PollFormData,
} from 'components/poll/pollTypes'
import { useEffect, useRef } from 'react'
import { processedFormFilesState } from 'state/formStates'
import ImageUploader from 'components/form/ImageUploader'

type PollEditAnswerProps = {
  control: Control<PollFormData, unknown>
  index: number
  defaultLanguage: string
  onOpenOptionTranslationDialog: (index: number) => void
  validateTranslations: (name: `answers.${number}.texts`) => null | string
  remove: (index: number) => void
  onMoveAnswer: (dragIndex: number, hoverIndex: number) => void
  answer: PollFormAnswer
}

type Item = {
  id: string
  index: number
}

const MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD = 600
const MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD = 600

const PollEditAnswer: React.FC<PollEditAnswerProps> = ({
  control,
  onOpenOptionTranslationDialog,
  validateTranslations,
  index,
  defaultLanguage,
  remove,
  onMoveAnswer,
  answer,
}) => {
  const { formatMessage } = useIntl()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const ref = useRef<HTMLDivElement>(null)
  const processedFormFiles = useRecoilValue(
    processedFormFilesState(`answers.${index}.link`),
  )

  const {
    field: answerField,
    fieldState: { isDirty, error },
  } = useController({
    name: `answers.${index}.texts.${defaultLanguage}`,
    control,
    rules: { required: true, maxLength: POLL_ANSWER_MAX_LENGTH },
  })

  const { field: linkField } = useController({
    name: `answers.${index}.link`,
    control,
  })

  useEffect(() => {
    if (processedFormFiles[0]) {
      linkField.onChange(processedFormFiles[0]?.url)
    }
  }, [processedFormFiles])

  const [{ opacity }, drag] = useDrag(
    () => ({
      type: 'PollAnswer',
      item: { id: answer.id, index },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: monitor.isDragging(),
      }),
    }),
    [answer, index],
  )

  const [, drop] = useDrop(
    () => ({
      accept: 'PollAnswer',
      hover(item: Item, monitor) {
        if (!ref.current) {
          return
        }
        const dragIndex = item.index
        const hoverIndex = index

        if (dragIndex === hoverIndex) {
          return
        }

        const hoverBoundingRect = ref.current?.getBoundingClientRect()

        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

        const clientOffset = monitor.getClientOffset()

        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return
        }

        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return
        }

        onMoveAnswer(dragIndex, hoverIndex)

        item.index = hoverIndex
      },
    }),
    [index],
  )

  drag(drop(ref))

  return (
    <Stack width="100%" ref={ref} style={{ opacity, cursor: 'move' }}>
      {supportLanguages.length > 1 && (
        <Stack direction="row" justifyContent="end">
          <Button
            size="small"
            startIcon={<EditIcon />}
            onClick={(): void => {
              onOpenOptionTranslationDialog(index)
            }}
          >
            {formatMessage({
              id: 'portal_item_edit.button.manage_localization',
            })}
          </Button>
        </Stack>
      )}

      <FormControl>
        <TextField
          {...answerField}
          error={!!error}
          size="small"
          label={`${formatMessage(
            {
              id: 'poll_edit.label.option',
            },
            { index: index + 1 },
          )}${supportLanguages.length < 2 ? '' : ` (${defaultLanguage})`}`}
          variant="outlined"
          fullWidth
        />

        {!!isDirty && !!validateTranslations(`answers.${index}.texts`) && (
          <FormHelperText error>
            {formatMessage(
              {
                id: 'portal_item_edit.error.missing_translations',
              },
              {
                missingTranslations: validateTranslations(
                  `answers.${index}.texts`,
                ),
              },
            )}
          </FormHelperText>
        )}
      </FormControl>

      <Box textAlign="right">
        <IconButton
          size="small"
          onClick={(): void => {
            remove(index)
          }}
          aria-label={formatMessage({
            id: 'general.icon_button.delete',
          })}
        >
          <DeleteIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box
        sx={{
          width: processedFormFiles.length > 0 ? 200 : 50,
          height: processedFormFiles.length > 0 ? 200 : 50,
        }}
      >
        <ImageUploader
          formName={`answers.${index}.link`}
          maxImageWidth={MAX_IMAGE_WIDTH_IN_PX_FOR_UPLOAD}
          maxImageHeight={MAX_IMAGE_HEIGHT_IN_PX_FOR_UPLOAD}
          iconButtonOnly={true}
          defaultImageUrl={answer.link}
        />
      </Box>
    </Stack>
  )
}

export default PollEditAnswer
