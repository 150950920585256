import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'

const Wrapper = styled(Stack)`
  width: 100%;
  background: ${({ theme }) => theme.palette.info.main};
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  height: 20px;
`

const ValueWrapper = styled(Stack)<{ value: number }>`
  width: ${({ value }) => value}%;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  border-top-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding-left: 6px;
  min-width: 30px;
`

type BarProgressProps = {
  value: number
  max: number
  extraText?: string | number
}

const BarProgress: React.FC<BarProgressProps> = ({ value, max, extraText }) => {
  const percentage = max > 0 && value > 0 ? Math.round((value / max) * 100) : 0

  return (
    <Wrapper direction="row">
      {percentage > 0 && (
        <ValueWrapper value={percentage}>
          {percentage}%{extraText ? ` (${extraText})` : ''}
        </ValueWrapper>
      )}
    </Wrapper>
  )
}

export default BarProgress
