export const CASE_TITLE_MAX_LENGTH = 250
export const CASE_DESCRIPTION_MAX_LENGTH = 2000
export const CASE_ATTACHMENTS_MAX_COUNT = 5
export const MAP_DEFAULT_REGION = 'FI'
export const MAP_DEFAULT_ZOOM = 12
export const MAP_DEFAULT_CENTER = { lat: 60.1695, lng: 24.9354 }
export const MAP_DEFAULT_LANGUAGE = 'fi'

export enum CurrentCaseListView {
  LIST = 'LIST',
  MAP = 'MAP',
}

export enum CaseTaskStatus {
  UNASSIGNED = 'UNASSIGNED',
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
}

export enum Shareability {
  PRIVATE = 'PRIVATE',
  SHAREABLE = 'SHAREABLE',
  SHARED = 'SHARED',
}

export const SHAREABILITY_TRANSITIONS: Record<Shareability, string> = {
  [Shareability.PRIVATE]: 'makePrivate',
  [Shareability.SHAREABLE]: 'makeShareable',
  [Shareability.SHARED]: 'makeShared',
}

export const CASE_TASK_STATUS_LABEL: Record<CaseTaskStatus, string> = {
  [CaseTaskStatus.UNASSIGNED]: 'action_list.label.status.unassigned',
  [CaseTaskStatus.ASSIGNED]: 'action_list.label.status.assigned',
  [CaseTaskStatus.IN_PROGRESS]: 'action_list.label.status.in_progress',
  [CaseTaskStatus.DONE]: 'action_list.label.status.done',
  [CaseTaskStatus.CANCELLED]: 'action_list.label.status.cancelled',
}

export const CASE_SHAREABILITY_LABEL: Record<Shareability, string> = {
  [Shareability.PRIVATE]: 'case_detail.label.private',
  [Shareability.SHAREABLE]: 'case_detail.label.shareable',
  [Shareability.SHARED]: 'case_detail.label.shared',
}

export enum VirtualPortalCaseStatus {
  NEW = 'NEW',
  SENDING = 'SENDING',
  SENT = 'SENT',
  UNSENT = 'UNSENT',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CANCELLED = 'CANCELLED',
}

export enum CaseStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FORWARDING = 'FORWARDING',
  FORWARDED = 'FORWARDED',
  NOT_FORWARDED = 'NOT_FORWARDED',
  CANCELLED = 'CANCELLED',
}

export const CASE_STATUS_LABEL: Record<
  CaseStatus | VirtualPortalCaseStatus,
  string
> = {
  [CaseStatus.NEW]: 'case_list.state.created',
  [VirtualPortalCaseStatus.SENDING]: 'case_list.state.sending',
  [VirtualPortalCaseStatus.SENT]: 'case_list.state.sent',
  [VirtualPortalCaseStatus.UNSENT]: 'case_list.state.unsent',
  [CaseStatus.IN_PROGRESS]: 'case_list.state.in_progress',
  [CaseStatus.COMPLETED]: 'case_list.state.done',
  [CaseStatus.CANCELLED]: 'case_list.state.canceled',
  [CaseStatus.FORWARDING]: 'case_list.state.forwarding',
  [CaseStatus.FORWARDED]: 'case_list.state.forwarded',
  [CaseStatus.NOT_FORWARDED]: 'case_list.state.not_forwarded',
}

export enum Period {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const PERIOD_LABEL: Record<Period, string> = {
  [Period.WEEK]: 'case_filter.period.week',
  [Period.MONTH]: 'case_filter.period.month',
  [Period.YEAR]: 'case_filter.period.year',
}

export enum AddCaseStep {
  ITEM = 'ITEM',
  CATEGORY = 'CATEGORY',
  ADDRESS = 'ADDRESS',
  CASE_INFO = 'CASE_INFO',
  VISIBILITY = 'VISIBILITY',
  REPORTER = 'REPORTER',
}

export enum CaseFileSource {
  REPORT_UPLOAD = 'REPORT_UPLOAD',
  CASE_UPLOAD = 'CASE_UPLOAD',
  VIDEO_CAPTURE = 'VIDEO_CAPTURE',
  SERVICE_UPLOAD = 'SERVICE_UPLOAD',
  COMMENT_UPLOAD = 'COMMENT_UPLOAD',
  TASK_NOTE_UPLOAD = 'TASK_NOTE_UPLOAD',
  OTHER = 'OTHER',
}

export const FILE_SOURCE_LABEL: Record<CaseFileSource, string> = {
  [CaseFileSource.REPORT_UPLOAD]: 'file_list.source.report',
  [CaseFileSource.CASE_UPLOAD]: 'file_list.source.case',
  [CaseFileSource.VIDEO_CAPTURE]: 'file_list.source.video_inspection',
  [CaseFileSource.SERVICE_UPLOAD]: 'file_list.source.service_app',
  [CaseFileSource.COMMENT_UPLOAD]: 'file_list.source.comment_upload',
  [CaseFileSource.TASK_NOTE_UPLOAD]: 'file_list.source.task_note_upload',
  [CaseFileSource.OTHER]: '',
}

export enum CaseTab {
  TASKS = 'TASKS',
  FILES = 'FILES',
  LOGS = 'LOGS',
  INFORMATION = 'INFORMATION',
  COMMENTS = 'COMMENTS',
}

export enum CaseResponsiveness {
  FULL = 'FULL',
  LIMITED = 'LIMITED',
  NONE = 'NONE',
}

export enum CaseListColumn {
  IMAGE = 'image',
  ADDRESS_AND_TITLE = 'addressAndTitle',
  SHAREABILITY = 'shareability',
  STATUS = 'status',
  CREATED_BY = 'createdBy',
  UPDATED = 'updated',
  CREATED = 'created',
}

export const CASE_COLUMN_LABEL: Record<CaseListColumn, string> = {
  [CaseListColumn.IMAGE]: '',
  [CaseListColumn.ADDRESS_AND_TITLE]: 'case_list.cases.header.address',
  [CaseListColumn.SHAREABILITY]: 'case_list.cases.header.shareability',
  [CaseListColumn.STATUS]: 'case_list.cases.header.status',
  [CaseListColumn.CREATED_BY]: 'case_list.cases.header.reported_by',
  [CaseListColumn.UPDATED]: 'case_list.cases.header.updated_at',
  [CaseListColumn.CREATED]: 'case_list.cases.header.created_at',
}

export const CASE_SHAREABILITIES_DEFAULT = [
  Shareability.PRIVATE,
  Shareability.SHAREABLE,
  Shareability.SHARED,
]

export const CASE_STATUSES_DEFAULT = [
  CaseStatus.IN_PROGRESS,
  CaseStatus.NEW,
  CaseStatus.COMPLETED,
  CaseStatus.FORWARDED,
  CaseStatus.FORWARDING,
  CaseStatus.NOT_FORWARDED,
]

export const CASE_PERIODS_DEFAULT = Period.YEAR

export enum CaseOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING',
}
