import { useCallback, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import useSWR from 'swr'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { nanoid } from 'nanoid'
import { enqueueSnackbar } from 'notistack'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import { BackButtonGrey, MainHeader } from 'components/StyledComponents'
import useApi from 'hooks/useApi'
import { hasUnsavedChangesState } from 'state/formStates'
import { portalSettingState } from 'state/portalSettingStates'
import {
  type Category,
  type CategoriesData,
  type CategoryFormData,
} from 'components/category/categoryTypes'
import useRoute from 'hooks/useNavigate'
import { type Locale } from 'types'
import { Path } from 'commonConstants'
import ErrorIcon from 'assets/icons/error_icon.svg'
import { cleanCategoryFormData, sortCategories } from 'utils/categoryUtils'
import CategoryEditTree from 'components/category/edit/CategoryEditTree'
import { convertDataToLocalizedString } from 'utils/stringUtils'
import CategoryEditDialog from 'components/category/edit/CategoryEditDialog'
import { type ItemBasic } from 'components/item/itemTypes'

enum ConfirmContext {
  DELETE = 'DELETE',
  ACTIVATE = 'ACTIVATE',
  INACTIVATE = 'INACTIVATE',
  ENABLE_LOCATION = 'ENABLE_LOCATION',
  DISABLE_LOCATION = 'DISABLE_LOCATION',
  ENABLE_IMAGE = 'ENABLE_IMAGE',
  DISABLE_IMAGE = 'DISABLE_IMAGE',
}

const CategoryEditPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const { sendPutRequest } = useApi()
  const portalSetting = useRecoilValue(portalSettingState)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [categories, setCategories] = useState<CategoryFormData[]>([])
  const { data: categoriesData } = useSWR<CategoriesData>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/categories/root`
      : null,
  )
  const [mode, setMode] = useState<'edit' | 'add'>('add')
  const [selectedCategory, setSelectedCategory] =
    useState<CategoryFormData | null>(null)
  const currentPathsRef = useRef<string[]>([])
  const setHasUnsavedChanges = useSetRecoilState(hasUnsavedChangesState)
  const [isSaving, setIsSaving] = useState(false)
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false)
  const [confirmContext, setConfirmContext] = useState<ConfirmContext>()

  const mapResponseToFormData = (
    categories: Category[],
    allItems: ItemBasic[],
  ): CategoryFormData[] => {
    const mapItems = (categories: Category[]): CategoryFormData[] => {
      return categories.map((category) => {
        const items = category.itemIds
          ?.map((itemId) => allItems.find((item) => item.id === itemId))
          .filter(Boolean) as ItemBasic[]

        const formData: CategoryFormData = {
          id: category.id,
          index: category.index,
          active: category.active,
          names: category.names,
          items,
          resourcesAllowed: category.resourcesAllowed,
          locationRequired: category.locationRequired,
          subcategories: category.subcategories
            ? mapItems(category.subcategories)
            : [],
        }

        return formData
      })
    }

    return mapItems(categories)
  }

  useEffect(() => {
    if (categoriesData) {
      const sortedCategories = sortCategories<Category>(
        categoriesData.categories,
      )
      const newData = mapResponseToFormData(
        sortedCategories,
        categoriesData.items,
      )

      setCategories(newData)
    }
  }, [categoriesData])

  const handleSave = async (): Promise<void> => {
    if (!portalSetting) {
      return
    }
    try {
      setIsSaving(true)

      await sendPutRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/categories/root`,
        {
          categories: cleanCategoryFormData(
            categories,
            portalSetting.supportedLanguages,
          ),
        },
      )

      enqueueSnackbar(
        formatMessage({ id: 'edit_categories.message.updated' }),
        {
          variant: 'success',
        },
      )
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
      setHasUnsavedChanges(false)
    }
  }

  const handleGoBack = (): void => {
    goTo(Path.CATEGORIES_LIST)
  }

  const handleAddCategory = (paths: string[]): void => {
    setIsEditDialogOpen(true)
    setSelectedCategory(null)
    setMode('add')
    currentPathsRef.current = paths
  }

  const handleSaveCategory = useCallback(
    (itemIds: string[], namesData: Record<Locale, string>): void => {
      setIsEditDialogOpen(false)
      const names = convertDataToLocalizedString(namesData)
      const items =
        categoriesData?.items.filter((item) => itemIds.includes(item.id)) ?? []

      const addCategory = (
        categories: CategoryFormData[],
        paths: string[],
      ): CategoryFormData[] => {
        if (paths.length === 0) {
          return [
            ...categories.map((category, index) => ({
              ...category,
              index: index + 1,
            })),
            {
              id: `dummy-${nanoid(6)}`,
              names,
              active: true,
              index: categories.length + 1,
              subcategories: [],
              items,
              locationRequired: true,
              resourcesAllowed: true,
            },
          ]
        }

        const currentId = paths.shift()

        return categories.map((category) => {
          if (category.id === currentId) {
            category.subcategories = addCategory(
              category.subcategories ?? [],
              paths,
            )
          }

          return category
        })
      }

      const updateCategory = (
        categories: CategoryFormData[],
        paths: string[],
      ): CategoryFormData[] => {
        const currentId = paths.shift()

        return categories.map((category) => {
          if (!category) {
            return category
          }
          if (category.id === currentId) {
            if (paths.length === 0) {
              const updatedCategory = {
                ...category,
                names,
              }

              return toggleCategory(updatedCategory, 'items', items)
            } else {
              category.subcategories = updateCategory(
                category.subcategories || [],
                paths,
              )
            }
          }
          return category
        })
      }

      const newData =
        mode === 'add'
          ? addCategory([...categories], currentPathsRef.current)
          : updateCategory([...categories], currentPathsRef.current)

      setCategories(newData)
      setHasUnsavedChanges(true)
    },
    [mode, categories, currentPathsRef.current, categoriesData],
  )

  const handleDeleteCategory = (paths: string[]): void => {
    currentPathsRef.current = paths
    setConfirmContext(ConfirmContext.DELETE)
    setIsConfirmDialogOpen(true)
  }

  const handleToggleCategory = (paths: string[], active: boolean): void => {
    currentPathsRef.current = paths
    setConfirmContext(
      active ? ConfirmContext.ACTIVATE : ConfirmContext.INACTIVATE,
    )
    setIsConfirmDialogOpen(true)
  }

  const handleToggleResourcesAllowed = (
    paths: string[],
    enable: boolean,
  ): void => {
    currentPathsRef.current = paths
    setConfirmContext(
      enable ? ConfirmContext.ENABLE_IMAGE : ConfirmContext.DISABLE_IMAGE,
    )
    setIsConfirmDialogOpen(true)
  }

  const handleToggleLocationRequired = (
    paths: string[],
    enable: boolean,
  ): void => {
    currentPathsRef.current = paths
    setConfirmContext(
      enable ? ConfirmContext.ENABLE_LOCATION : ConfirmContext.DISABLE_LOCATION,
    )
    setIsConfirmDialogOpen(true)
  }

  const handleEditCategory = (
    paths: string[],
    category: CategoryFormData,
  ): void => {
    currentPathsRef.current = paths
    setSelectedCategory(category)
    setIsEditDialogOpen(true)
    setMode('edit')
  }

  const handleCloseEditDialog = (): void => {
    setIsEditDialogOpen(false)
  }

  const handleCloseConfirmDialog = (): void => {
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedDeleteCategory = (): void => {
    if (currentPathsRef.current.length) {
      const deleteCategory = (
        categories: CategoryFormData[],
        paths: string[],
      ): CategoryFormData[] => {
        const currentId = paths.shift()

        return categories
          .map((category) => {
            if (!category) {
              return category
            }
            if (category.id === currentId) {
              if (paths.length === 0) {
                return undefined
              } else {
                category.subcategories = deleteCategory(
                  category.subcategories || [],
                  paths,
                )
              }
            }
            return category
          })
          .filter((category) => category !== undefined)
      }

      const newData = deleteCategory([...categories], currentPathsRef.current)
      setCategories(newData)
      setHasUnsavedChanges(true)
    }

    setIsConfirmDialogOpen(false)
  }

  const toggleCategory = (
    category: CategoryFormData,
    field: 'active' | 'locationRequired' | 'resourcesAllowed' | 'items',
    fieldValue: unknown,
  ): CategoryFormData => {
    const updatedCategory = { ...category, [field]: fieldValue }

    if (updatedCategory.subcategories) {
      updatedCategory.subcategories = updatedCategory.subcategories.map(
        (subcategory) => toggleCategory(subcategory, field, fieldValue),
      )
    }

    return updatedCategory
  }

  const toggleCategoryFormData = (
    categories: CategoryFormData[],
    paths: string[],
    field: 'active' | 'locationRequired' | 'resourcesAllowed',
    fieldValue: boolean,
  ): CategoryFormData[] => {
    const currentId = paths.shift()

    return categories.map((category) => {
      if (!category) {
        return category
      }
      if (category.id === currentId) {
        if (paths.length === 0) {
          return toggleCategory(category, field, fieldValue)
        } else {
          category.subcategories = toggleCategoryFormData(
            category.subcategories || [],
            paths,
            field,
            fieldValue,
          )
        }
      }

      return category
    })
  }

  const handleConfirmedActivateCategory = (): void => {
    if (currentPathsRef.current.length) {
      const newData = toggleCategoryFormData(
        [...categories],
        currentPathsRef.current,
        'active',
        true,
      )

      setCategories(newData)
      setHasUnsavedChanges(true)
    }
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedDeactivateCategory = (): void => {
    if (currentPathsRef.current.length) {
      const newData = toggleCategoryFormData(
        [...categories],
        currentPathsRef.current,
        'active',
        false,
      )

      setCategories(newData)
      setHasUnsavedChanges(true)
    }
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedEnableImage = (): void => {
    if (currentPathsRef.current.length) {
      const newData = toggleCategoryFormData(
        [...categories],
        currentPathsRef.current,
        'resourcesAllowed',
        true,
      )

      setCategories(newData)
      setHasUnsavedChanges(true)
    }
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedDisableImage = (): void => {
    if (currentPathsRef.current.length) {
      const newData = toggleCategoryFormData(
        [...categories],
        currentPathsRef.current,
        'resourcesAllowed',
        false,
      )

      setCategories(newData)
      setHasUnsavedChanges(true)
    }
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedEnableLocation = (): void => {
    if (currentPathsRef.current.length) {
      const newData = toggleCategoryFormData(
        [...categories],
        currentPathsRef.current,
        'locationRequired',
        true,
      )

      setCategories(newData)
      setHasUnsavedChanges(true)
    }
    setIsConfirmDialogOpen(false)
  }

  const handleConfirmedDisableLocation = (): void => {
    if (currentPathsRef.current.length) {
      const newData = toggleCategoryFormData(
        [...categories],
        currentPathsRef.current,
        'locationRequired',
        false,
      )

      setCategories(newData)
      setHasUnsavedChanges(true)
    }
    setIsConfirmDialogOpen(false)
  }

  const findCategories = (
    paths: string[],
    categories: CategoryFormData[],
  ): CategoryFormData[] => {
    if (paths.length === 0) {
      return categories
    }

    const currentId = paths.shift()

    const category = categories.find((category) => category.id === currentId)

    if (!category) {
      return []
    }

    return findCategories([...paths], category.subcategories ?? [])
  }

  const handleMoveCategory = useCallback(
    (paths: string[], from: number, to: number): void => {
      console.log(paths, from, to)
      const clone = [...categories]
      const targetCategories = findCategories([...paths], clone)
      const [removed] = targetCategories.splice(from, 1)
      targetCategories.splice(to, 0, removed)
      targetCategories.forEach((category, index) => {
        category.index = index + 1
      })

      setCategories(clone)
      setHasUnsavedChanges(true)
    },
    [categories],
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <Stack height="100%" width="100%">
        <Stack direction="row" width="100%" spacing={2} marginBottom={2}>
          <Stack flexGrow={1} direction="row" spacing={1}>
            <BackButtonGrey
              onClick={handleGoBack}
              size="small"
              aria-label={formatMessage({
                id: 'general.icon_button.go_back',
              })}
            >
              <ArrowBackIcon />
            </BackButtonGrey>

            <MainHeader>
              {formatMessage({
                id: 'edit_categories.button.edit_categories',
              })}
            </MainHeader>
          </Stack>

          <LoadingButton
            variant="contained"
            size="small"
            loading={isSaving}
            onClick={() => {
              void handleSave()
            }}
            color="secondary"
          >
            {formatMessage({ id: 'general.button.save' })}
          </LoadingButton>
        </Stack>

        <Stack height="100%" flexGrow={1} overflow={'auto'}>
          <CategoryEditTree
            categories={categories}
            onAddCategory={handleAddCategory}
            onEditCategory={handleEditCategory}
            onToggleCategory={handleToggleCategory}
            onToggleResourcesAllowed={handleToggleResourcesAllowed}
            onToggleLocationRequired={handleToggleLocationRequired}
            onDeleteCategory={handleDeleteCategory}
            onMoveCategory={handleMoveCategory}
          />
        </Stack>

        <CategoryEditDialog
          isOpen={isEditDialogOpen}
          title={formatMessage({
            id:
              mode === 'add'
                ? 'edit_categories.title.add_category'
                : 'edit_categories.title.edit_category',
          })}
          items={categoriesData?.items ?? []}
          selectedCategory={selectedCategory}
          onSave={handleSaveCategory}
          onClose={handleCloseEditDialog}
        />

        <Dialog open={isConfirmDialogOpen} maxWidth="xs">
          <DialogTitle textAlign="center">
            <Stack alignItems="center" spacing={2}>
              <ErrorIcon />
              <Box textAlign="center">
                {confirmContext === ConfirmContext.DELETE &&
                  formatMessage({ id: 'edit_categories.delete_confirm.title' })}
                {confirmContext === ConfirmContext.ACTIVATE &&
                  formatMessage({
                    id: 'edit_categories.active_confirm.title',
                  })}
                {confirmContext === ConfirmContext.INACTIVATE &&
                  formatMessage({
                    id: 'edit_categories.inactive_confirm.title',
                  })}
              </Box>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText textAlign="center">
              {confirmContext === ConfirmContext.DELETE &&
                formatMessage({ id: 'edit_categories.delete_confirm.message' })}
              {confirmContext === ConfirmContext.ACTIVATE &&
                formatMessage({
                  id: 'edit_categories.active_confirm.message',
                })}
              {confirmContext === ConfirmContext.INACTIVATE &&
                formatMessage({
                  id: 'edit_categories.inactive_confirm.message',
                })}
              {confirmContext === ConfirmContext.ENABLE_IMAGE &&
                formatMessage({
                  id: 'category_edit.enable_image_confirm.message',
                })}
              {confirmContext === ConfirmContext.DISABLE_IMAGE &&
                formatMessage({
                  id: 'category_edit.disable_image_confirm.message',
                })}
              {confirmContext === ConfirmContext.ENABLE_LOCATION &&
                formatMessage({
                  id: 'category_edit.enable_location_confirm.message',
                })}
              {confirmContext === ConfirmContext.DISABLE_LOCATION &&
                formatMessage({
                  id: 'category_edit.disable_location_confirm.message',
                })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack spacing={1} width="100%">
              {confirmContext === ConfirmContext.DELETE && (
                <Button
                  onClick={handleConfirmedDeleteCategory}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  {formatMessage({
                    id: 'edit_categories.delete_confirm.button.delete_category',
                  })}
                </Button>
              )}
              {confirmContext === ConfirmContext.ACTIVATE && (
                <Button
                  onClick={handleConfirmedActivateCategory}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  {formatMessage({
                    id: 'edit_categories.button.show_category',
                  })}
                </Button>
              )}
              {confirmContext === ConfirmContext.INACTIVATE && (
                <Button
                  onClick={handleConfirmedDeactivateCategory}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  {formatMessage({
                    id: 'edit_categories.button.hide_category',
                  })}
                </Button>
              )}

              {confirmContext === ConfirmContext.ENABLE_IMAGE && (
                <Button
                  onClick={handleConfirmedEnableImage}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  {formatMessage({
                    id: 'category_edit.button.enable_add_image_for_report',
                  })}
                </Button>
              )}
              {confirmContext === ConfirmContext.DISABLE_IMAGE && (
                <Button
                  onClick={handleConfirmedDisableImage}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  {formatMessage({
                    id: 'category_edit.button.disable_add_image_for_report',
                  })}
                </Button>
              )}

              {confirmContext === ConfirmContext.ENABLE_LOCATION && (
                <Button
                  onClick={handleConfirmedEnableLocation}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  {formatMessage({
                    id: 'category_edit.button.enable_add_location_for_report',
                  })}
                </Button>
              )}
              {confirmContext === ConfirmContext.DISABLE_LOCATION && (
                <Button
                  onClick={handleConfirmedDisableLocation}
                  variant="contained"
                  fullWidth
                  color="error"
                >
                  {formatMessage({
                    id: 'category_edit.button.disable_add_location_for_report',
                  })}
                </Button>
              )}

              <Button
                onClick={handleCloseConfirmDialog}
                variant="outlined"
                fullWidth
                color="error"
                autoFocus
              >
                {formatMessage({
                  id: 'general.button.cancel',
                })}
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </Stack>
    </DndProvider>
  )
}

export default CategoryEditPage
