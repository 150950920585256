import {
  type CategoryFormData,
  type CleanedCategoryFormData,
  type Category,
  type CategoryEditorData,
  type CategoryBasic,
} from 'components/category/categoryTypes'

const convertTranslationKeysToUpperCase = (
  translations: Record<string, string>,
): Record<string, string> => {
  const newTranslations: Record<string, string> = {}
  Object.keys(translations).forEach((key) => {
    newTranslations[key.toUpperCase()] = translations[key]
  })
  return newTranslations
}

const getCategoryTranslation = (
  translations: Record<string, string>,
  locale: string,
  fallbackLocale: string,
): string => {
  const keys = Object.keys(translations)

  if (keys.length === 0) {
    return ''
  }

  const newTranslations = convertTranslationKeysToUpperCase(translations)

  return newTranslations[locale] ?? newTranslations[fallbackLocale]
}

function findCategory(
  categoryId: string,
  categories: Category[] | CategoryBasic[],
): Category | CategoryBasic | null {
  for (const categoryInput of categories) {
    if (categoryInput.id === categoryId) {
      return categoryInput
    }

    if (categoryInput.subcategories) {
      const foundSubcategory = findCategory(
        categoryId,
        categoryInput.subcategories,
      )
      if (foundSubcategory) {
        return foundSubcategory
      }
    }
  }

  return null
}

function cleanCategoryFormData(
  categories: CategoryFormData[],
  supportedLanguages: string[],
): CleanedCategoryFormData[] {
  return categories
    .sort((a, b) => a.index - b.index)
    .map((category, index) => ({
      ...category,
      names: category.names.filter((name) =>
        supportedLanguages.includes(name.language),
      ),
      id: category.id?.startsWith('dummy-') ? null : category.id,
      index: index + 1,
      itemIds: category.items?.map((item) => item.id) ?? [],
      subcategories: category.subcategories
        ? cleanCategoryFormData(category.subcategories, supportedLanguages)
        : [],
    }))
}

function cleanCategoryEditorData(
  categories: CategoryEditorData[],
  supportedLanguages: string[],
): CleanedCategoryFormData[] {
  return categories.map((category, index) => ({
    id: category.id,
    index: index + 1,
    names: category.names.filter((name) =>
      supportedLanguages.includes(name.language),
    ),
    active: category.active === true || category.active === undefined,
    subcategories: category.subcategories
      ? cleanCategoryEditorData(category.subcategories, supportedLanguages)
      : [],
    resourcesAllowed:
      category.resourcesAllowed === true ||
      category.resourcesAllowed === undefined,
    itemIds: category.itemIds,
    locationRequired:
      category.locationRequired === true ||
      category.locationRequired === undefined,
  }))
}

function parseCategoryToEditorData(
  categories: Category[],
): CategoryEditorData[] {
  return categories
    .sort((a, b) => a.index - b.index)
    .map((category, index) => ({
      id: category.id,
      names: category.names,
      active: category.active,
      subcategories: category.subcategories
        ? parseCategoryToEditorData(category.subcategories)
        : [],
      itemIds: category.items?.map((item) => item.id) ?? [],
      resourcesAllowed: category.resourcesAllowed,
      locationRequired: category.locationRequired,
    }))
}

function sortCategories<T extends CategoryBasic>(categories: T[]): T[] {
  return categories
    .concat()
    .sort((a, b) => (a.uncategorized ? 1 : a.index - b.index))
    .map(
      (category): T => ({
        ...category,
        subcategories: category.subcategories
          ? sortCategories(category.subcategories)
          : [],
      }),
    )
}

function getActiveCategories(categories: Category[]): Category[] {
  return categories
    .filter((category) => category.active)
    .map(
      (category): Category => ({
        ...category,
        subcategories: category.subcategories
          ? getActiveCategories(category.subcategories)
          : [],
      }),
    )
}

function getItemRelatedCategories<T extends CategoryBasic>(
  categories: T[],
  itemId: string,
): T[] {
  return categories
    .filter(
      (category) =>
        !category.itemIds?.length ||
        (category.itemIds &&
          category.itemIds.length > 0 &&
          category.itemIds.includes(itemId)),
    )
    .map(
      (category): T => ({
        ...category,
        subcategories: category.subcategories
          ? getItemRelatedCategories(category.subcategories, itemId)
          : [],
      }),
    )
}

export {
  findCategory,
  sortCategories,
  getCategoryTranslation,
  convertTranslationKeysToUpperCase,
  cleanCategoryFormData,
  parseCategoryToEditorData,
  cleanCategoryEditorData,
  getActiveCategories,
  getItemRelatedCategories,
}
