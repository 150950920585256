import { useState } from 'react'

import SubCategoryEditTree from './SubCategoryEditTree'
import { type CategoryFormData } from '../categoryTypes'
import {
  CollapseIcon,
  ExpandIcon,
  TreeView,
} from 'components/category/CategoryStyledComponents'

type CategoryEditTreeProps = {
  categories: CategoryFormData[]
  onAddCategory: (paths: string[]) => void
  onEditCategory: (paths: string[], category: CategoryFormData) => void
  onToggleCategory: (paths: string[], active: boolean) => void
  onToggleResourcesAllowed: (paths: string[], resourcesAllowed: boolean) => void
  onToggleLocationRequired: (paths: string[], locationRequired: boolean) => void
  onDeleteCategory: (paths: string[]) => void
  onMoveCategory: (paths: string[], index: number, toIndex: number) => void
}

const CategoryEditTree: React.FC<CategoryEditTreeProps> = ({
  categories,
  onAddCategory,
  onEditCategory,
  onToggleCategory,
  onToggleResourcesAllowed,
  onToggleLocationRequired,
  onDeleteCategory,
  onMoveCategory,
}) => {
  const [currentHoverItem, setCurrentHoverItem] = useState<string | null>(null)

  return (
    <TreeView
      slots={{
        collapseIcon: CollapseIcon,
        expandIcon: ExpandIcon,
      }}
    >
      <SubCategoryEditTree
        paths={[]}
        categories={categories}
        onAddCategory={onAddCategory}
        onEditCategory={onEditCategory}
        onToggleCategory={onToggleCategory}
        onToggleResourcesAllowed={onToggleResourcesAllowed}
        onToggleLocationRequired={onToggleLocationRequired}
        onDeleteCategory={onDeleteCategory}
        onMoveCategory={onMoveCategory}
        setCurrentHoverItem={setCurrentHoverItem}
        currentHoverItem={currentHoverItem}
      />
    </TreeView>
  )
}

export default CategoryEditTree
