import { type LocalizedString } from 'types'

const getNameFromEmail = (email: string): string => {
  const rawName = email.split('@')[0] ?? ''
  const splitters = ['.', '_', '-']

  for (const splitter of splitters) {
    if (rawName.includes(splitter)) {
      const names = rawName
        .split(splitter)
        .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
      return names.join(' ')
    }
  }

  return rawName.charAt(0).toUpperCase() + rawName.slice(1)
}

function isValidEmail(input: string): boolean {
  const pattern =
    /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
  return pattern.test(input)
}

function isValidPhoneNumber(input: string): boolean {
  const cleanedInput = input.replace(/\s+/g, '')

  const patternPlus = /^\+[0-9]{9,}$/
  const patternZero = /^0[0-9]{6,}$/

  if (cleanedInput.startsWith('+')) {
    return patternPlus.test(cleanedInput)
  } else if (cleanedInput.startsWith('0')) {
    return patternZero.test(cleanedInput)
  } else {
    return false
  }
}

function hasNumber(input: string): boolean {
  const numberPattern = /[0-9]/
  return numberPattern.test(input)
}

function hasSpecialChar(input: string): boolean {
  const specialCharPattern = /[!@#$%^&*[\]{}()?"!@#%&/\\,><':;|_~+\-.]/
  return specialCharPattern.test(input)
}

function subWords(inputString: string, n: number): string {
  const words = inputString.split(' ')

  if (words.length <= n) {
    return inputString
  }

  words.length = n
  const remainingWords = words.join(' ')
  return remainingWords
}

function shortenUuid(uuid: string): string {
  const chunks = uuid.split('-')
  if (chunks.length) {
    return chunks[0].substring(0, 4)
  }

  return uuid.substring(0, 4)
}

function nameInitials(name?: string): string {
  if (!name) {
    return ''
  }

  const words = name.split(' ')
  const initials = words.map((word) => word.charAt(0)).join('')
  return initials
}

const convertDataToLocalizedString = (
  data: Record<string, string>,
): LocalizedString[] => {
  const results: LocalizedString[] = []
  const keys = Object.keys(data)
  keys.forEach((key) => {
    if (data[key]) {
      results.push({
        language: key.toUpperCase(),
        content: data[key],
      })
    }
  })

  return results
}

const convertLocalizedStringToData = (
  contents?: LocalizedString[],
): Record<string, string> => {
  if (!contents) {
    return {}
  }

  const results: Record<string, string> = {}

  contents.forEach((content) => (results[content.language] = content.content))

  return results
}

export {
  getNameFromEmail,
  isValidEmail,
  isValidPhoneNumber,
  subWords,
  shortenUuid,
  nameInitials,
  convertDataToLocalizedString,
  convertLocalizedStringToData,
  hasNumber,
  hasSpecialChar,
}
