import { nanoid } from 'nanoid'

import ResourceList from 'components/resource/ResourceList'
import { type ResourceBasic } from 'types'
import { FileType } from 'commonConstants'
import { extractFilenameFromUrl } from 'utils/fileUtils'

type ImageWrapperProps = {
  url: string
  size: number
}

const ImageWrapper: React.FC<ImageWrapperProps> = ({ url, size }) => {
  const reportResources: ResourceBasic[] = [
    {
      id: nanoid(),
      format: FileType.IMAGE,
      uri: url,
      name: extractFilenameFromUrl(url),
    },
  ]
  return <ResourceList resources={reportResources} size={size} max={1} />
}

export default ImageWrapper
