import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useRecoilValue } from 'recoil'
import dayjs from 'dayjs'
import Markdown from 'react-markdown'
import styled from '@mui/material/styles/styled'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SingleSelectIcon from '@mui/icons-material/CheckCircle'
import MultiSelectIcon from '@mui/icons-material/Checklist'

import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import { DialogActions, Typography } from '@mui/material'
import { type PollFormData } from 'components/poll/pollTypes'
import { InfoText } from 'components/StyledComponents'

type PollPreviousDialogProps = {
  isOpen: boolean
  poll: PollFormData
  onClose: () => void
}

const PreviewDialog = styled(Dialog)`
  .MuiPaper-root {
    background: ${({ theme }) => theme.palette.info.light};
  }
`

const PollWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.background.paper};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  .show-less {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
  }
`

const TitleWrapper = styled(Stack)`
  background: #1664ef;
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-top-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  color: #fff;
  padding: 16px;

  h1 {
    font-size: 24px;
    font-weight: 500;
  }
`

const QuestionWrapper = styled(Typography)`
  font-size: 24px;
  font-weight: 500;
`

const AnswerWrapper = styled('label')`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  cursor: pointer;
`

const AnswerImageWrapper = styled(Stack)<{ url: string }>`
  width: 100%;
  height: 200px;
  align-items: center;

  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  background-image: url('${({ url }) => url}');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`

const PollPreviousDialog: React.FC<PollPreviousDialogProps> = ({
  isOpen,
  poll,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
  const portalSetting = useRecoilValue(portalSettingState)
  const [isSeeMore, setIsSeeMore] = useState(false)

  useEffect(() => {
    if (portalSetting) {
      setSelectedLanguage(portalSetting.defaultLanguage)
    }
  }, [portalSetting])

  const handleClose = (reason: 'backdropClick' | 'escapeKeyDown'): void => {
    if (reason !== 'backdropClick') {
      onClose()
    }
  }

  const handleSeeMore = (): void => {
    setIsSeeMore(true)
  }

  const handleSeeLess = (): void => {
    setIsSeeMore(false)
  }

  return (
    <PreviewDialog
      open={isOpen}
      onClose={(event, reason) => {
        handleClose(reason)
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        {formatMessage({
          id: 'poll_preview_dialog.header',
        })}
      </DialogTitle>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
        aria-label={formatMessage({
          id: 'general.button.close',
        })}
      >
        <CloseIcon />
      </IconButton>
      <DialogActions>
        <Stack width="100%" spacing={2}>
          <Typography variant="body2">
            {formatMessage({
              id: 'poll_preview_dialog.description',
            })}
          </Typography>
          <Stack direction="row" spacing={1}>
            {supportLanguages.map((language) => (
              <Button
                key={language}
                variant={
                  selectedLanguage === language ? 'contained' : 'outlined'
                }
                onClick={() => {
                  setSelectedLanguage(language)
                }}
                size="small"
              >
                {language}
              </Button>
            ))}
          </Stack>
        </Stack>
      </DialogActions>
      <DialogContent>
        <Stack spacing={2}>
          {selectedLanguage && (
            <PollWrapper>
              <TitleWrapper>
                {poll.expiry && dayjs().isBefore(poll.expiry) && (
                  <Box>
                    {formatMessage(
                      {
                        id: 'poll_preview_dialog.n_days_left',
                      },
                      {
                        count: poll.expiry.diff(dayjs(), 'day'),
                      },
                    )}
                  </Box>
                )}
                <Typography variant="h1">
                  {poll.titles?.[selectedLanguage]}
                </Typography>
              </TitleWrapper>
              <Stack padding={2} spacing={1}>
                <div className={isSeeMore ? '' : 'show-less'}>
                  <Markdown>{poll.descriptions?.[selectedLanguage]}</Markdown>
                </div>

                <Box textAlign="right">
                  {!isSeeMore && (
                    <Button onClick={handleSeeMore} size="small" variant="text">
                      {formatMessage({
                        id: 'news_preview_dialog.button.see_more',
                      })}
                    </Button>
                  )}

                  {isSeeMore && (
                    <Button onClick={handleSeeLess} size="small" variant="text">
                      {formatMessage({
                        id: 'news_preview_dialog.button.see_less',
                      })}
                    </Button>
                  )}
                </Box>

                <QuestionWrapper>
                  {poll.questions?.[selectedLanguage]}
                </QuestionWrapper>

                {poll.multichoice && (
                  <InfoText>
                    <MultiSelectIcon fontSize="small" />{' '}
                    {formatMessage({
                      id: 'poll_preview_dialog.select_multiple',
                    })}{' '}
                  </InfoText>
                )}

                {!poll.multichoice && (
                  <InfoText>
                    <SingleSelectIcon fontSize="small" />{' '}
                    {formatMessage({
                      id: 'poll_preview_dialog.select_one',
                    })}
                  </InfoText>
                )}

                {poll.answers.map((answer) => (
                  <AnswerWrapper key={answer.id}>
                    <Stack direction="row" spacing={1} alignItems="baseline">
                      {poll.multichoice && (
                        <input type="checkbox" name="answers" />
                      )}
                      {!poll.multichoice && (
                        <input type="radio" name="answers" />
                      )}

                      <Stack>
                        <Box>{answer.texts?.[selectedLanguage]}</Box>
                        <InfoText>0% (0)</InfoText>
                      </Stack>
                    </Stack>

                    {answer.link && <AnswerImageWrapper url={answer.link} />}
                  </AnswerWrapper>
                ))}

                <Button variant="contained">
                  {formatMessage({
                    id: 'poll_preview_dialog.button.send',
                  })}
                </Button>

                <InfoText width="100%" justifyContent="center">
                  {formatMessage(
                    {
                      id: 'poll_preview_dialog.n_responses',
                    },
                    { count: 0 },
                  )}
                </InfoText>
              </Stack>
            </PollWrapper>
          )}
        </Stack>
      </DialogContent>
    </PreviewDialog>
  )
}

export default PollPreviousDialog
