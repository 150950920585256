import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { useRecoilValue } from 'recoil'
import { useSnackbar } from 'notistack'
import Markdown from 'react-markdown'
import styled from '@mui/material/styles/styled'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import LoadingButton from '@mui/lab/LoadingButton'
import AddIcon from '@mui/icons-material/Add'
import ExpiryIcon from '@mui/icons-material/HourglassTop'
import OpenIcon from '@mui/icons-material/Public'
import RestrictedIcon from '@mui/icons-material/Lock'
import PersonIcon from '@mui/icons-material/Person'
import ChecklistIcon from '@mui/icons-material/Checklist'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import InfoIcon from '@mui/icons-material/InfoOutlined'

import {
  InfoText,
  MainHeader,
  StrongText,
  SubContentWrapperComponent,
  SubHeader,
} from 'components/StyledComponents'
import EmptyPollIcon from 'assets/icons/empty_poll.svg'
import {
  portalSettingState,
  sortedSupportLanguagesSelector,
} from 'state/portalSettingStates'
import { FeatureAccess, PortalSection } from 'components/role/roleConstants'
import useMember from 'hooks/useMember'
import {
  type PollExpiryFormData,
  type Poll,
  type PollResultConfigFormData,
} from 'components/poll/pollTypes'
import {
  PollCategory,
  PollResultExposure,
  PollVisibility,
} from 'components/poll/pollConstants'
import { FileType, Path } from 'commonConstants'
import useRoute from 'hooks/useNavigate'
import usePortalSetting from 'hooks/usePortalSetting'
import { convertLocalizedStringToData, shortenUuid } from 'utils/stringUtils'
import {
  ExposureAlwaysIcon,
  ExposureAfterIcon,
  ExposureNeverIcon,
} from 'components/icons/Icons'
import BarProgress from 'components/progress/BarProgress'
import useApi from 'hooks/useApi'
import ErrorIcon from 'assets/icons/error_icon.svg'
import PollEditExpiryDialog from 'components/poll/PollEditExpiryDialog'
import PollEditResultConfigDialog from 'components/poll/PollEditResultConfigDialog'
import ResourceWrapper from 'components/resource/ResourceWrapper'
import ImageWrapper from 'components/helpers/ImageWrapper'

const Wrapper = styled(Stack)`
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  position: relative;

  :hover {
    border-color: ${({ theme }) => theme.palette.primary.main};
    box-shadow: 0px 0px 0px 3px #bbc6f1;
  }
`

const ViewTabsWrapper = styled(Stack)`
  background: ${({ theme }) => theme.palette.info.light};
  border-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;

  & .MuiButton-endIcon .counter {
    padding: 0px 8px;
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    background: ${({ theme }) => theme.palette.primary.light};
    font-size: 12px;
    color: ${({ theme }) => theme.palette.text.primary};
  }

  & .MuiButton-text {
    & .counter {
      background: ${({ theme }) => theme.palette.primary.light};
      color: ${({ theme }) => theme.palette.text.primary};
    }
  }
`

const StatusWrapper = styled(Box)`
  padding: 8px 20px;
  border-top-left-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  border-bottom-right-radius: ${({ theme }) => 2 * theme.shape.borderRadius}px;
  align-items: center;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  display: inline-block;
`

const OngoingStatus = styled(StatusWrapper)`
  background: ${({ theme }) => theme.palette.success.main};
  color: ${({ theme }) => theme.palette.success.contrastText};
`

const DraftStatus = styled(StatusWrapper)`
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.text.primary};
`

const InactiveStatus = styled(StatusWrapper)`
  background: ${({ theme }) => theme.palette.background.default};
  border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
  border-right: 1px solid ${({ theme }) => theme.palette.divider};
`

const QuestionWrapper = styled(Typography)`
  font-size: 20px;
  font-weight: 500;
`

const PollListPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { goTo } = useRoute()
  const supportLanguages = useRecoilValue(sortedSupportLanguagesSelector)
  const portalSetting = useRecoilValue(portalSettingState)
  const { enqueueSnackbar } = useSnackbar()
  const { getLocalizedContent, formatDate } = usePortalSetting()
  const { sendPostRequest, sendDeleteRequest } = useApi()
  const {
    data: pollsData,
    isLoading,
    mutate: mutatePolls,
  } = useSWR<Poll[]>(
    portalSetting
      ? `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting.id
        }/polls`
      : null,
  )
  const { checkAccesses } = useMember()
  const [currentView, setCurrentView] = useState<PollCategory | 'logs'>(
    PollCategory.ONGOING,
  )
  const [openPollsTranslation, setOpenPollsTranslation] = useState<Set<string>>(
    new Set(),
  )
  const [openPollsDescription, setOpenPollsDescription] = useState<Set<string>>(
    new Set(),
  )
  const [menuEl, setMenuEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(menuEl)
  const [selectedPoll, setSelectedPoll] = useState<Poll | null>(null)
  const [isActiveConfirmDialogOpen, setIsActiveConfirmDialogOpen] =
    useState(false)
  const [isEndConfirmDialogOpen, setIsEndConfirmDialogOpen] = useState(false)
  const [isEditExpiryDialogOpen, setIsEditExpiryDialogOpen] = useState(false)
  const [isEditResultConfigDialogOpen, setIsEditResultConfigDialogOpen] =
    useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const handleCloseMenu = (): void => {
    setMenuEl(null)
  }

  const activePolls = useMemo(
    () =>
      pollsData?.filter(
        (poll) =>
          poll.category === PollCategory.ONGOING ||
          poll.category === PollCategory.ENDED,
      ) ?? [],
    [pollsData],
  )

  const draftPolls = useMemo(
    () =>
      pollsData?.filter((poll) => poll.category === PollCategory.DRAFT) ?? [],
    [pollsData],
  )

  const inactivePolls = useMemo(
    () =>
      pollsData?.filter((poll) => poll.category === PollCategory.ARCHIVED) ??
      [],
    [pollsData],
  )

  const currentPolls = useMemo(
    () =>
      currentView === PollCategory.ONGOING
        ? activePolls
        : currentView === PollCategory.DRAFT
        ? draftPolls
        : currentView === PollCategory.ENDED
        ? inactivePolls
        : pollsData ?? [],
    [currentView, activePolls, draftPolls, inactivePolls, pollsData],
  )

  const handleOpenMenu = (
    event: React.MouseEvent<HTMLElement>,
    poll: Poll,
  ): void => {
    setMenuEl(event.currentTarget)
    setSelectedPoll(poll)
  }

  const handleEditPoll = (): void => {
    if (selectedPoll) {
      goTo(`${Path.POLLS_EDIT}/${selectedPoll.id}`)
    }
  }

  const handleCloseActiveConfirmDialog = (): void => {
    setIsActiveConfirmDialogOpen(false)
    setSelectedPoll(null)
  }

  const handleCloseEndConfirmDialog = (): void => {
    setIsEndConfirmDialogOpen(false)
    setSelectedPoll(null)
  }

  const handleSetActive = (): void => {
    handleCloseMenu()
    setIsActiveConfirmDialogOpen(true)
  }

  const handleSetEnd = (): void => {
    handleCloseMenu()
    setIsEndConfirmDialogOpen(true)
  }

  const setActive = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedPoll) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/polls/${selectedPoll.id}:activate`,
      )

      handleCloseActiveConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutatePolls()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedPoll])

  const setEnd = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedPoll) {
      return
    }

    try {
      setIsSaving(true)
      await sendPostRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/polls/${selectedPoll.id}:end`,
      )

      handleCloseEndConfirmDialog()
      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutatePolls()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedPoll])

  const handleCopy = (): void => {
    goTo(`${Path.POLLS_EDIT}/${selectedPoll?.id}?isCopy=true`)
  }

  const handleEditPollExpiry = (): void => {
    handleCloseMenu()
    setIsEditExpiryDialogOpen(true)
  }

  const handleUpdateExpiry = useCallback(
    async (data: PollExpiryFormData): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        setIsSaving(true)
        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/polls/${data.id}:updateExpiry${
            data.expiry
              ? `?timestamp=${encodeURIComponent(data.expiry.format())}`
              : ''
          }`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        await mutatePolls()
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
        setIsEditExpiryDialogOpen(false)
      }
    },
    [portalSetting],
  )

  const handleEditExpiryDialogClose = (): void => {
    setIsEditExpiryDialogOpen(false)
  }

  const handleEditPollResultConfig = (): void => {
    handleCloseMenu()
    setIsEditResultConfigDialogOpen(true)
  }

  const handleUpdateResultConfig = useCallback(
    async (data: PollResultConfigFormData): Promise<void> => {
      if (!portalSetting) {
        return
      }

      try {
        setIsSaving(true)
        await sendPostRequest(
          `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
            portalSetting?.id
          }/polls/${
            data.id
          }:updateResultConfig${`?exposure=${data.exposure}`}&retentionDays=${
            data.retentionDays
          }`,
        )

        enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
          variant: 'success',
        })

        await mutatePolls()
      } catch (error) {
        console.error(error)
      } finally {
        setIsSaving(false)
        setIsEditResultConfigDialogOpen(false)
      }
    },
    [portalSetting],
  )

  const handleEditResultConfigDialogClose = (): void => {
    setIsEditResultConfigDialogOpen(false)
  }

  const deleteDraftPoll = useCallback(async (): Promise<void> => {
    if (!portalSetting || !selectedPoll) {
      return
    }

    try {
      setIsSaving(true)
      await sendDeleteRequest(
        `${process.env.REACT_APP_API_PATH ?? ''}/portals/${
          portalSetting?.id
        }/polls/${selectedPoll.id}`,
      )

      enqueueSnackbar(formatMessage({ id: 'general.text.changes_saved' }), {
        variant: 'success',
      })

      await mutatePolls()
    } catch (error) {
      console.error(error)
    } finally {
      setIsSaving(false)
    }
  }, [portalSetting, selectedPoll])

  const handleDeleteDraftPoll = (): void => {
    handleCloseMenu()
    void deleteDraftPoll()
  }

  return (
    <Stack height="100%" width="100%" spacing={2} overflow="auto">
      <Stack direction="row" width="100%" spacing={2}>
        <Box flexGrow={1}>
          <MainHeader>
            {formatMessage({ id: 'poll_list.header' })}{' '}
            <Tooltip
              title={formatMessage({
                id: 'poll_list.label.creating_poll_help_text',
              })}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>
          </MainHeader>
        </Box>

        {pollsData &&
          pollsData?.length > 0 &&
          checkAccesses({ [PortalSection.POLLS]: [FeatureAccess.WRITE] }) && (
            <Button
              variant="contained"
              size="small"
              startIcon={<AddIcon />}
              onClick={() => {
                goTo(Path.POLLS_ADD)
              }}
              color="secondary"
            >
              {formatMessage({ id: 'poll_list.button.create_poll' })}
            </Button>
          )}
      </Stack>
      <ViewTabsWrapper direction="row" padding={1} spacing={1}>
        <Button
          size="small"
          variant={currentView === PollCategory.ONGOING ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(PollCategory.ONGOING)
          }}
          endIcon={
            activePolls.length > 0 && (
              <Box className="counter">{activePolls.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'poll_list.view.active' })}
        </Button>
        <Button
          size="small"
          variant={currentView === PollCategory.DRAFT ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(PollCategory.DRAFT)
          }}
          endIcon={
            draftPolls.length > 0 && (
              <Box className="counter">{draftPolls.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'poll_list.view.draft' })}
        </Button>
        <Button
          size="small"
          variant={currentView === PollCategory.ENDED ? 'contained' : 'text'}
          onClick={() => {
            setCurrentView(PollCategory.ENDED)
          }}
          endIcon={
            inactivePolls.length > 0 && (
              <Box className="counter">{inactivePolls.length}</Box>
            )
          }
        >
          {formatMessage({ id: 'poll_list.view.archived' })}
        </Button>
      </ViewTabsWrapper>
      <Stack flexGrow={1} overflow="auto" spacing={2} padding={0.3}>
        {pollsData &&
          pollsData.length === 0 &&
          checkAccesses({ [PortalSection.POLLS]: [FeatureAccess.WRITE] }) && (
            <Wrapper
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Stack textAlign="center" spacing={2} alignItems="center">
                <EmptyPollIcon />
                <Box width="60%">
                  <SubHeader>
                    {formatMessage({
                      id: 'poll_list.label.start_creating_poll',
                    })}
                  </SubHeader>
                  <Typography variant="body2">
                    {formatMessage({
                      id: 'poll_list.label.creating_poll_help_text',
                    })}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      goTo(Path.POLLS_ADD)
                    }}
                    color="secondary"
                  >
                    {formatMessage({
                      id: 'poll_list.button.create_poll',
                    })}
                  </Button>
                </Box>
              </Stack>
            </Wrapper>
          )}

        {isLoading && <CircularProgress />}

        {currentPolls.map((poll) => (
          <Wrapper key={poll.id} spacing={1}>
            <Stack
              direction="row"
              width={'100%'}
              alignItems="center"
              spacing={1}
              paddingRight={1}
            >
              <Stack flexGrow={1} direction="row" spacing={1}>
                {poll.category === PollCategory.ONGOING && (
                  <OngoingStatus>
                    {formatMessage({
                      id: 'poll_list.status.ongoing',
                    })}
                  </OngoingStatus>
                )}
                {poll.category === PollCategory.ENDED && (
                  <InactiveStatus>
                    {formatMessage({
                      id: 'poll_list.status.ended',
                    })}
                  </InactiveStatus>
                )}
                {poll.category === PollCategory.DRAFT && (
                  <DraftStatus>
                    {formatMessage({
                      id: 'poll_list.status.draft',
                    })}
                  </DraftStatus>
                )}
                {poll.category === PollCategory.ARCHIVED && (
                  <InactiveStatus>
                    {formatMessage({
                      id: 'poll_list.status.archived',
                    })}
                  </InactiveStatus>
                )}

                <Stack direction="row" alignItems="center" spacing={1}>
                  {poll.visibility === PollVisibility.OPEN && (
                    <Tooltip
                      title={formatMessage({
                        id: 'poll_edit.label.visibility.open',
                      })}
                      placement="bottom"
                    >
                      <OpenIcon fontSize="small" />
                    </Tooltip>
                  )}

                  {poll.visibility === PollVisibility.RESTRICTED && (
                    <Tooltip
                      title={formatMessage({
                        id: 'poll_edit.label.visibility.restricted',
                      })}
                      placement="bottom"
                    >
                      <RestrictedIcon fontSize="small" />
                    </Tooltip>
                  )}

                  {poll.question.multichoice && (
                    <Tooltip
                      title={formatMessage({
                        id: 'poll_edit.label.multichoice',
                      })}
                      placement="bottom"
                    >
                      <ChecklistIcon fontSize="small" />
                    </Tooltip>
                  )}

                  <Chip
                    label={getLocalizedContent(poll.item.names)}
                    color="info"
                    size="small"
                    disabled={!poll.item.active}
                  />
                </Stack>
              </Stack>

              {poll.expiry && (
                <>
                  <Tooltip
                    title={formatMessage({
                      id: 'alert_list.tooltip.expiration',
                    })}
                    placement="bottom"
                  >
                    <InfoText>
                      <ExpiryIcon fontSize="small" />
                      {formatDate(poll.expiry)}
                    </InfoText>
                  </Tooltip>
                  <Divider orientation="vertical" flexItem />
                </>
              )}

              <Stack direction="row" alignItems="center" spacing={1}>
                <InfoText>
                  {poll.result.configuration.exposure ===
                    PollResultExposure.ALWAYS && (
                    <Tooltip
                      title={formatMessage({
                        id: 'poll_edit.label.exposure.always',
                      })}
                      placement="bottom"
                    >
                      <ExposureAlwaysIcon fontSize="small" />
                    </Tooltip>
                  )}

                  {poll.result.configuration.exposure ===
                    PollResultExposure.AFTER_POLL_END && (
                    <Tooltip
                      title={formatMessage({
                        id: 'poll_edit.label.exposure.after_poll_end',
                      })}
                      placement="bottom"
                    >
                      <ExposureAfterIcon fontSize="small" />
                    </Tooltip>
                  )}

                  {poll.result.configuration.exposure ===
                    PollResultExposure.NEVER && (
                    <Tooltip
                      title={formatMessage({
                        id: 'poll_edit.label.exposure.never',
                      })}
                      placement="bottom"
                    >
                      <ExposureNeverIcon fontSize="small" />
                    </Tooltip>
                  )}

                  {poll.result.configuration.retentionDays > 0 &&
                    `${poll.result.configuration.retentionDays} ${formatMessage(
                      {
                        id: 'portal_item_edit.visibility_period.days',
                      },
                    )}`}
                </InfoText>
              </Stack>
              <Divider orientation="vertical" flexItem />

              <Stack direction="row" alignItems="center" spacing={1}>
                <InfoText>
                  <PersonIcon fontSize="small" />
                  {poll.result.participants}
                </InfoText>
              </Stack>
              <Divider orientation="vertical" flexItem />

              <InfoText
                sx={{
                  cursor: 'pointer',
                }}
              >
                ID {shortenUuid(poll.id)}
              </InfoText>

              {checkAccesses({
                [PortalSection.POLLS]: [FeatureAccess.WRITE],
              }) &&
                (poll._operations.canEdit ||
                  poll._operations.canCopy ||
                  poll._operations.canActivate ||
                  poll._operations.canEnd ||
                  poll._operations.canRemove ||
                  poll._operations.canUpdateExpiry ||
                  poll._operations.canUpdateResultConfig) && (
                  <IconButton
                    onClick={(event) => {
                      handleOpenMenu(event, poll)
                    }}
                    size="small"
                    aria-label={formatMessage({
                      id: 'general.icon_button.see_more',
                    })}
                    role="button"
                    color="inherit"
                  >
                    <MoreVertIcon
                      sx={{
                        fontSize: 16,
                      }}
                    />
                  </IconButton>
                )}
            </Stack>

            {(poll.titles?.length > 0 || poll.descriptions?.length > 0) && (
              <Box paddingX={2}>
                {openPollsDescription.has(poll.id) && (
                  <Button
                    variant="text"
                    size="small"
                    endIcon={<ArrowUpIcon />}
                    onClick={(): void => {
                      setOpenPollsDescription((prev) => {
                        prev.delete(poll.id)
                        return new Set(prev)
                      })
                    }}
                  >
                    {formatMessage({
                      id: 'poll_list.button.hide_title_description',
                    })}
                  </Button>
                )}

                {!openPollsDescription.has(poll.id) && (
                  <Button
                    variant="text"
                    size="small"
                    endIcon={<ArrowDownIcon />}
                    onClick={(): void => {
                      setOpenPollsDescription((prev) => {
                        prev.add(poll.id)
                        return new Set(prev)
                      })
                    }}
                  >
                    {formatMessage({
                      id: 'poll_list.button.show_title_description',
                    })}
                  </Button>
                )}
              </Box>
            )}

            {openPollsTranslation.has(poll.id) && (
              <Stack spacing={1} padding={2}>
                {supportLanguages.map((language) => (
                  <Stack key={language} marginBottom={1}>
                    <Stack direction="row" spacing={1}>
                      <Chip label={language} color="info" size="small" />
                    </Stack>

                    {openPollsDescription.has(poll.id) && (
                      <>
                        <StrongText>
                          {
                            convertLocalizedStringToData(poll.titles)?.[
                              language
                            ]
                          }
                        </StrongText>
                        <Typography variant="body2">
                          <Markdown>
                            {
                              convertLocalizedStringToData(poll.descriptions)?.[
                                language
                              ]
                            }
                          </Markdown>
                        </Typography>
                      </>
                    )}

                    <QuestionWrapper>
                      {
                        convertLocalizedStringToData(poll.question.texts)?.[
                          language
                        ]
                      }
                    </QuestionWrapper>

                    {Object.keys(poll.result.answerVoteCountMap).map(
                      (answerKey) =>
                        poll.result.answerVoteCountMap[answerKey] && (
                          <SubContentWrapperComponent
                            key={answerKey}
                            direction="row"
                          >
                            <Stack spacing={1} marginTop={1} flex={1}>
                              <Stack flexGrow={1}>
                                <Typography variant="body2">
                                  {
                                    convertLocalizedStringToData(
                                      poll.result.answerVoteCountMap[answerKey]
                                        ?.texts,
                                    )?.[language]
                                  }
                                </Typography>
                              </Stack>

                              <BarProgress
                                value={
                                  poll.result.answerVoteCountMap[answerKey]
                                    .votes
                                }
                                max={poll.result.participants}
                                extraText={
                                  poll.result.answerVoteCountMap[answerKey]
                                    .votes
                                }
                              />
                            </Stack>

                            {poll.result.answerVoteCountMap[answerKey].link && (
                              <ResourceWrapper
                                size={70}
                                url={
                                  poll.result.answerVoteCountMap[answerKey].link
                                }
                                format={FileType.IMAGE}
                                imageSize="cover"
                              />
                            )}
                          </SubContentWrapperComponent>
                        ),
                    )}
                  </Stack>
                ))}

                <Box>
                  <Button
                    variant="text"
                    size="small"
                    endIcon={<ArrowUpIcon />}
                    onClick={(): void => {
                      setOpenPollsTranslation((prev) => {
                        prev.delete(poll.id)
                        return new Set(prev)
                      })
                    }}
                  >
                    {formatMessage({
                      id: 'alert_list.button.hide_translations',
                    })}
                  </Button>
                </Box>
              </Stack>
            )}

            {!openPollsTranslation.has(poll.id) && (
              <Stack spacing={1} padding={2}>
                {openPollsDescription.has(poll.id) && (
                  <>
                    <StrongText>{getLocalizedContent(poll.titles)}</StrongText>
                    <Typography variant="body2">
                      <Markdown>
                        {getLocalizedContent(poll.descriptions)}
                      </Markdown>
                    </Typography>
                  </>
                )}

                <QuestionWrapper>
                  {getLocalizedContent(poll.question.texts)}
                </QuestionWrapper>

                {Object.keys(poll.result.answerVoteCountMap).map(
                  (answerKey) =>
                    poll.result.answerVoteCountMap[answerKey] && (
                      <SubContentWrapperComponent
                        key={answerKey}
                        direction="row"
                      >
                        <Stack spacing={1} marginTop={1} flex={1}>
                          <Stack flexGrow={1}>
                            <Typography variant="body2">
                              {poll.result.answerVoteCountMap[answerKey] &&
                                getLocalizedContent(
                                  poll.result.answerVoteCountMap[answerKey]
                                    .texts,
                                )}
                            </Typography>
                          </Stack>

                          <BarProgress
                            value={
                              poll.result.answerVoteCountMap[answerKey].votes
                            }
                            max={poll.result.participants}
                            extraText={
                              poll.result.answerVoteCountMap[answerKey].votes
                            }
                          />
                        </Stack>

                        {poll.result.answerVoteCountMap[answerKey].link && (
                          <ImageWrapper
                            size={70}
                            url={
                              poll.result.answerVoteCountMap[answerKey].link ??
                              ''
                            }
                          />
                        )}
                      </SubContentWrapperComponent>
                    ),
                )}

                {supportLanguages.length > 1 && (
                  <Box>
                    <Button
                      variant="text"
                      size="small"
                      endIcon={<ArrowDownIcon />}
                      onClick={(): void => {
                        setOpenPollsTranslation((prev) => {
                          prev.add(poll.id)
                          return new Set(prev)
                        })
                      }}
                    >
                      {formatMessage({
                        id: 'alert_list.button.show_translations',
                      })}
                    </Button>
                  </Box>
                )}
              </Stack>
            )}
          </Wrapper>
        ))}
      </Stack>
      <Menu
        anchorEl={menuEl}
        open={openMenu}
        onClose={handleCloseMenu}
        role="menu"
      >
        {selectedPoll?._operations.canEdit && (
          <MenuItem onClick={handleEditPoll}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.edit',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedPoll?._operations.canActivate && (
          <MenuItem onClick={handleSetActive}>
            <ListItemText>
              {formatMessage({
                id: 'poll_edit.button.activate',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedPoll?._operations.canEnd && (
          <MenuItem onClick={handleSetEnd}>
            <ListItemText>
              {formatMessage({
                id: 'poll_list.button.set_end',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedPoll?._operations.canCopy && (
          <MenuItem onClick={handleCopy}>
            <ListItemText>
              {formatMessage({
                id: 'poll_list.button.copy_to_draft',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedPoll?._operations.canUpdateExpiry && (
          <MenuItem onClick={handleEditPollExpiry}>
            <ListItemText>
              {formatMessage({
                id: 'poll_list.button.update_expiry',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedPoll?._operations.canUpdateResultConfig && (
          <MenuItem onClick={handleEditPollResultConfig}>
            <ListItemText>
              {formatMessage({
                id: 'poll_list.button.update_result_config',
              })}
            </ListItemText>
          </MenuItem>
        )}

        {selectedPoll?._operations.canRemove && (
          <MenuItem onClick={handleDeleteDraftPoll}>
            <ListItemText>
              {formatMessage({
                id: 'general.icon_button.delete',
              })}
            </ListItemText>
          </MenuItem>
        )}
      </Menu>

      <Dialog open={isActiveConfirmDialogOpen} maxWidth="xs">
        <DialogTitle sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'poll_list.dialog.active.title' })}
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'poll_list.dialog.active.content' })}
        </DialogContent>
        <DialogActions>
          <Stack width="100%" spacing={1}>
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void setActive()
              }}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'alert_list.button.set_active',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseActiveConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog open={isEndConfirmDialogOpen}>
        <DialogTitle>
          <Stack alignItems="center" spacing={2}>
            <ErrorIcon />
            <Box textAlign="center">
              {formatMessage({ id: 'poll_list.end_confirm.title' })}
            </Box>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center' }}>
          {formatMessage({ id: 'poll_list.end_confirm.content' })}
        </DialogContent>
        <DialogActions>
          <Stack spacing={1} width="100%">
            <LoadingButton
              loading={isSaving}
              fullWidth
              onClick={(): void => {
                void setEnd()
              }}
              autoFocus
              variant="contained"
              color="error"
            >
              {formatMessage({
                id: 'poll_list.button.set_end',
              })}
            </LoadingButton>
            <Button
              fullWidth
              onClick={handleCloseEndConfirmDialog}
              variant="outlined"
              color="error"
            >
              {formatMessage({
                id: 'general.button.cancel',
              })}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <PollEditExpiryDialog
        poll={selectedPoll}
        isOpen={isEditExpiryDialogOpen}
        isSaving={isSaving}
        onSave={(data) => {
          void handleUpdateExpiry(data)
        }}
        onClose={handleEditExpiryDialogClose}
      />

      <PollEditResultConfigDialog
        poll={selectedPoll}
        isOpen={isEditResultConfigDialogOpen}
        isSaving={isSaving}
        onSave={(data) => {
          void handleUpdateResultConfig(data)
        }}
        onClose={handleEditResultConfigDialogClose}
      />
    </Stack>
  )
}

export default PollListPage
